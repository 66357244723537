/*

  module declaration and a few shims

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.services', [])

    .factory('d3', function() { return window.d3 })
    .factory('_', function() { return window._ })
    .factory('bowser', function() { return window.bowser })

    .factory('mix', function(storage){
      var mix = new heliosAudioMixer({ html5: true });
      if(storage.get('quipuDebug')) window.mix = mix;
      mix.setLogLvl(0);
      return mix;
    })

    .service('timeFormat', function(){
      return function timeFormat(seconds) {
        var m = Math.floor(seconds / 60) < 10 ? '0' + Math.floor(seconds / 60) : Math.floor(seconds / 60);
        var s = Math.floor(seconds - (m * 60)) < 10 ? '0' + Math.floor(seconds - (m * 60)) : Math.floor(seconds - (m * 60));
        return m + ':' + s;
      }
    })

})();