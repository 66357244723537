/*

  Audio "API"

    used all over the place

    holds data: quipu threads, captions

    handles themes ("tags") ie The Campaign

    also has a few data querying functions

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.services')
    .factory('AudioAPI', AudioAPI);

  function AudioAPI($q, $stateParams, storage) {

    var exports = {

      // values
      eventData:    [], // the quipu threads
      captionData:  null, // the captions

      testimony:    null,
      currentTime:  null,
      currentTag:   null,
      quipuReady:   false,
      headLocation: null,
      muted:        false,
      expanded:     false, // expanded = playing whole recording, unedited

      theme: {
        status: themStatus,
        next: nextInTheme,
        increment: incrementTheme,
        nextTitle: nextTitleCard,
      },

      find: {
        firstResponse: findFirstResponse,
        nextThread: findNextThread,
      },

      threadExists: threadExists

    }

    // how far through each tag are we?
    var themeProgress = {
      campaign:   0,
      operation:  0,
      lifeafter:  0,
      justice:    0
    }

    if(storage.get('quipu/themeProgress')){
      themeProgress = storage.get('quipu/themeProgress');
    }

    function themStatus(){
      return themeProgress[exports.currentTag];
    }

    /*

      find the next region in the current theme

    */
    function nextInTheme(tag){
      if(typeof themeProgress[tag] === 'undefined') return;

      var currentIndex = themeProgress[tag];

      var counter = 0;
      var result;

      var thread, e;
      for (var i = 0; i < exports.eventData.length; i++) {
        thread = exports.eventData[i];

        for (var j = 0; j < thread.events.length; j++) {
          e = thread.events[j];

          if(e.Tag === tag){
            if(counter === currentIndex){
              result = { thread: thread, region: e };
              break;
            }

            counter += 1;
            break;
          }
        }
        if(result) break;
      }

      return result;
    }

    function incrementTheme(tag){
      if(typeof themeProgress[tag] === 'undefined') return;
      themeProgress[tag] += 1;

      // todo: save progress to localStorage
    }

    function nextTitleCard(tag){
      if(typeof themeProgress[tag] === 'undefined') return;

      var index = themeProgress[tag];

      if(exports.captionData[tag].length > index)
        return {
          last: !!(exports.captionData[tag].length-1 === index),
          data: exports.captionData[tag][index]
        }
      else
        return;
    }

    function findFirstResponse(){
      var first;
      exports.eventData.map(function(thread){
        if(first) return;
        if(thread.Role === 'response') first = thread;
      })
      return first.UID;
    }

    function findNextThread(id){

      // locate this thread
      var thisIndex = _.findIndex(exports.eventData, function(thread){
        return !!(thread.UID === id)
      })

      // find next thread
      var nextIndex = thisIndex + 1

      if(exports.eventData[nextIndex]){
        return exports.eventData[nextIndex]
      } else {
        return exports.eventData[0]
      }
    }

    function threadExists(UID){
      return $q(function(resolve, reject){
        var thread = _.findWhere(exports.eventData, { UID: UID });
        thread ? resolve() : reject('nothread');
      })
    }

    return exports
  }
})();

