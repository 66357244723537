/*

  Tabletop
    Google Docs -> JSON

*/

(function () {
  'use strict';

  angular
    .module('angularApp.services')
    .factory('Gdocs', Gdocs);

  function Gdocs($q, storage){

    var cache = !!(storage.get('quipuDebug'));
    var data;

    function getSpreadsheetTabletop(){
      return $q(function(resolve, reject){
        if(data){
          resolve(data);
        } else {
          console.log('tabletop...');
          Tabletop.init({
            debug: true,
            simpleSheet: false,
            key: '1gRgm1YCmiRfkI8Er1lBOEoqQRk6kd9__8Ta5QwbiKUE',
            callback: function(_data) {
              data = _data;
              resolve(data);
            }
          })
        }
      })
    }

    function quipuThreads() {
      if(cache && storage.get('quipu.gdocs.threads')){
        return $q(function(resolve, reject){
          var data = storage.get('quipu.gdocs.threads');
          resolve( data );
        })
      } else {
        return getSpreadsheetTabletop()
          .then(function(data){
            var quipuDataset = [];
            var elements = data.quipu_data.elements;
            _.each(elements,function(o, i){
              o.events = _.filter(data.testimonies_knots.elements, function(element){
                return element.UID === o.UID;
              });

              // if(o.events && o.events.length > 0) {

              // } else {
              //   o.events = [{
              //     Action: "",
              //     File: "",
              //     LibraryName: "",
              //     MediaType: "",
              //     Name: "",
              //     Tag: "presentation",
              //     UID: "",
              //     isKnot: "yes",
              //     time_in: "00.01.04",
              //     time_out: "06.05.26"
              //   }]
              // }

              quipuDataset.push(o);
            });

            _.each(quipuDataset, function(o, i) { o.index = i; });
            storage.set('quipu.gdocs.threads', quipuDataset);
            return quipuDataset;
          })
      }
    }

    function libraries() {
      if(cache && storage.get('quipu.gdocs.libraries')){
        return $q(function(resolve, reject){
          var data = storage.get('quipu.gdocs.libraries');
          resolve( data );
        })
      } else {
        return getSpreadsheetTabletop()
          .then(function(data){
            var libs = {};
            _.each(data.library.elements, function(o,i) {
              if (!libs[o.LIBRARY_NAME]) {
                libs[o.LIBRARY_NAME] = {
                  current: 0,
                  items: []
                };
              }

              libs[o.LIBRARY_NAME].items.push(o);
            });
            storage.set('quipu.gdocs.libraries', libs);
            return libs;
          });
      }
    }

    function captions() {
      if(cache && storage.get('quipu.gdocs.captions')){
        return $q(function(resolve, reject){
          var data = storage.get('quipu.gdocs.captions');
          resolve( data );
        })
      } else {
        return getSpreadsheetTabletop()
          .then(function(data){

            // captions as array
            var captions = data.captions.elements;

            // group captions by categories
            var captions = {};
            data.captions.elements.map(function(caption){
              if(typeof captions[caption.ID] === 'undefined'){
                captions[caption.ID] = [];
              }

              captions[caption.ID].push(caption)
            })

            storage.set('quipu.gdocs.captions', captions);
            return captions;
          });
      }
    }

    function translations() {
      if(cache && storage.get('quipu.gdocs.translations')){
        return $q(function(resolve, reject){
          resolve( storage.get('quipu.gdocs.translations') );
        })
      } else {
        return getSpreadsheetTabletop()
          .then(function(data){
            var translations = [];
            _.each(data.interface_items.elements, function(o,i) {
              translations.push(o)
            });
            storage.set('quipu.gdocs.translations', translations);
            return translations
          });
      }
    }

    return {
      getSpreadsheetTabletop: getSpreadsheetTabletop,
      quipuThreads: quipuThreads,
      libraries: libraries,
      captions: captions,
      translations: translations,
    }
  }


})();
