/*

  Audio Player

    parent directive

    in a ui-view, so it gets re-instantiated every time the audio file changes.
    setup() runs every time.

    Children:
     - audio-meta
     - audio-timelines

*/
;(function () {

  'use strict';

  angular
    .module('angularApp.directives')
    .directive('audioPlayer', audioPlayer);

  function audioPlayer($rootScope, $timeout, $window, $state, $stateParams, $location, share, globals, dataStore, AudioAPI, BackgroundAPI, CDN, progress, storage, resize, Utilities, frameRunner) {
    return {

      restrict: 'E',
      replace: true,
      scope: {},

      templateUrl: 'js/modules/audio-player/audio-player.template.html',

      controller: function($scope, $element, $q, AudioAPI, BackgroundAPI) {

        $scope.appData = $scope.$parent.appData;

        $scope.viewData = {
          state: $stateParams,
          trackTitle:  '',
          trackNumber: '',
          trackDate:   '',
          caption:     '',
          style: {},
        }

        $scope.share = {
          facebook: share.facebook.bind(undefined, true),
          twitter:  share.twitter.bind(undefined, true)
        };

        $scope.api = this.api = AudioAPI;

        $scope.mediaEl = $element.find('audio');

        $scope.background = BackgroundAPI;

        // debug hax
        if(storage.get('quipuDebug')) window.audioEl = $scope.mediaEl[0]

        var playCountTimeout; // so we can delay before sending a listen count

        var ctrl = this;
        this.audio = $scope.mediaEl;

        $scope.status = {
          hideAll:    false,
          isLoaded:   false,
          isReady:    false,
          isPlaying:  false,
          isEnded:    false,
          isExpanded: !!(parseInt($state.params.expanded)),
          titleCard:  false, // show title card
          transition: false, // transitining to record view
          isMuted: $scope.api.muted
        };

        setup();

        // ********************************************************


        function setup(){

          // Find the active testimony data
          var index = 0
          $scope.testimonyData = _.find($scope.api.eventData, function(e){
            index += 1;
            return e.UID === $state.params.id
          });
          if(!$scope.testimonyData) return;
          $scope.testimonyData.index = index;

          // Promises to be resolved before the audio is initialized:

          // wait for quipu animation
          var waitForQuipu = $q(function(resolve, reject) {
            $scope.$watch('api.quipuReady', function(newVal) {
              if (newVal === true){ resolve('Quipu Ready'); }
            })
          });

          // wait for audio file to load
          var waitForPlayer = $q(function(resolve, reject) {

            // if we’re playing a video file (currently just webm), play it synced:
            if($scope.testimonyData.Filename.indexOf('.webm') !== -1){
              $scope.mediaEl = $('#syncedvid');

              $timeout(function(){
                BackgroundAPI.syncedVideo($scope.testimonyData.Filename)
                  .then(function(){
                    $scope.viewData.trackTitle = $scope.testimonyData.name;
                    $scope.status.isLoaded = true;
                    playCountTimeout = $timeout(function(){ progress.count($scope.testimonyData.UID) }, 10 * 1000);
                    resolve();
                  })
              })
            }

            // otherwise assume it’s an audio file and proceed normally
            else {

              if ($scope.testimonyData.Role == 'about') CDN.audio = 'assets/';
              loadAudio(CDN.audio + $scope.testimonyData.Filename)
                .then(function(){ resolve(); })
            }

          });

          $q.all([ waitForQuipu, waitForPlayer ]).then(function(data) {
            if(storage.get('quipuMute')) $scope.mediaEl[0].volume = 0;

            $scope.status.isReady = true;
            $scope.$broadcast('initTimelines');

            $scope.mediaElName = $scope.testimonyData.Name;

            angular.element(window).off('keydown').on('keydown', function(e){
              if(e.keyCode === 32)
                $scope.controls.togglePlay();
            })

          });

        }



        /**
         * Events
         */

        function loadAudio(src) {
          return $q(function(resolve, reject){

            $scope.mediaEl.attr('src', src);

            // Display the track title

            var role = $scope.testimonyData.Role;
            var name = $scope.testimonyData.Name;
            var loc  = $scope.testimonyData.Location;

            var number = parseInt($scope.testimonyData.UID);
            if(role === 'testimony') number = $scope.testimonyData.index + '/' + dataStore.testimonyCount

            var name   = $scope.testimonyData.Name;

            $scope.viewData.trackNumber = (number) ? number : undefined;

            if      (role === 'testimony'   ) $scope.viewData.trackTitle = (globals.lang === 'en' ? 'Testimony' : 'Testimonio')  + ' #'  + number + (loc ? ', '+loc : '');
            else if (role === 'response'    ) $scope.viewData.trackTitle = (globals.lang === 'en' ? 'Response'  : 'Respuesta')   + (name ? ' '+name : '') + (loc ? ', '+loc : '');
            else if (role === 'interview'   ) $scope.viewData.trackTitle = (globals.lang === 'en' ? 'Interview' : 'Entrevista' ) + (loc ? ', '+loc : '');
            else if (role === 'phoneline'   ) $scope.viewData.trackTitle = (globals.lang === 'en' ? 'Phoneline' : 'Línea de teléfono' )+ (loc ? ', '+loc : '');
            else if (role === 'archive'     ) $scope.viewData.trackTitle = (globals.lang === 'en' ? 'Archives' : 'Archivos' )
            else                              $scope.viewData.trackTitle = name

            var number = parseInt($scope.testimonyData.UID);
            $scope.viewData.trackNumber = (number) ? number : undefined;

            //
            $scope.viewData.trackDate = '';
            if($scope.testimonyData.Date){
              var d = new Date($scope.testimonyData.Date);
              if( !!(d.getTime()) ){
                // it’s a valid date
                var now = new Date();

                // get difference in days
                var days = Math.round( (now - d) / 1000 / 60 / 60 / 24 );

                if(days < 30){

                  if(days === 1)
                    $scope.viewData.trackDate = (globals.lang === 'en') ? '('+days+' day ago)' : '(hace '+diffInDay+' día)';
                  else
                    $scope.viewData.trackDate = (globals.lang === 'en') ? '('+days+' days ago)' : '(hace '+diffInDay+' días)';

                } else if( days > 30 && days < 365 ){
                  var months = Math.round( days / 30 );
                  // var remainder = days % 30;
                  if(months === 1)
                    $scope.viewData.trackDate = (globals.lang === 'en') ? '('+months+' month ago)' : '(hace '+months+' mes)';
                  else
                    $scope.viewData.trackDate = (globals.lang === 'en') ? '('+months+' months ago)' : '(hace '+months+' meses)';

                } else {
                  var years = Math.round( days / 365 )

                  if(years === 1)
                    $scope.viewData.trackDate = (globals.lang === 'en') ? '('+years+' year ago)' : '(hace '+years+' año)';
                  else
                    $scope.viewData.trackDate = (globals.lang === 'en') ? '('+years+' years ago)' : '(hace '+years+' años)';
                }
              }
            }

            // canplay event
            $scope.mediaEl.off('canplay').on('canplay', function() {
              $scope.mediaEl.off('canplay');
              $scope.viewData.duration = $scope.mediaEl[0].duration;
              $scope.mediaEl[0].pause();
              $scope.mediaEl[0].muted = $scope.status.isMuted;
              $scope.status.isLoaded = true;

              playCountTimeout = $timeout(function(){ progress.count($scope.testimonyData.UID) }, 1 * 1000);
              resolve();
            });

            $scope.mediaEl[0].play();
          })
        };

        /*

          Audio Controls

        */
        this.unload = function(callback) {
          ctrl.pause();
          $scope.mediaEl.attr('src', null);
          timeupdate(0);
          $scope.status.isLoaded = false;
          $scope.status.isPlaying = false;
          if (callback) callback();
        }

        this.play = function() {
          var videos = document.getElementsByTagName('video')
          for (var i = 0; i < videos.length; i++) {
            videos[i].play()
          }

          var videos = document.getElementsByTagName('video')
          for (var i = 0; i < videos.length; i++) {
            videos[i].loop = false
          }

          $scope.mediaEl[0].play();
        };

        this.pause = function() {
          var videos = document.getElementsByTagName('video')
          for (var i = 0; i < videos.length; i++) {
            videos[i].pause()
          }
          $scope.mediaEl[0].pause();
        };

        $rootScope.$on('play', this.play)
        $rootScope.$on('pause', this.pause)

        function mute() {
          $scope.mediaEl[0].muted = true;
          $scope.status.isMuted = $scope.api.muted = true;
        };

        function unmute() {
          $scope.mediaEl[0].muted = false;
          $scope.status.isMuted = $scope.api.muted = false;
        }

        $rootScope.$on('mute', mute)
        $rootScope.$on('unmute', unmute)

        /*

          Seek

        */
        var seekSuccessful;

        this.skip = function(time) {
          if(!time) return;
          if( !isFinite(time) ) return;

          seekSuccessful = false;
          $scope.mediaEl[0].currentTime = time;

          // show a spinner if the seek takes longer than 1s
          $timeout(function(){ if(!seekSuccessful) $scope.status.isReady = false }, 1000);
          $scope.mediaEl[0].addEventListener('seeked', seeked, false);
        };

        function seeked(){
          $scope.mediaEl[0].removeEventListener('seeked', seeked);
          seekSuccessful = true;
          $timeout(function(){ $scope.status.isReady = true }, 1000);
        }






        /*

          Playback control buttons
          (formerly in audio-meta directive)

        */

        $scope.controls = {

          togglePlay: function() {
            if( !$scope.status.isReady ) return;
            $scope.status.isPlaying ? ctrl.pause() : ctrl.play();
          },

          openRecord: function(){
            $scope.status.transition = true;
            $timeout($state.go.bind(null, 'quipu.takeaction'), 500)
          }
        }



        /*

          Listeners

        */
        $scope.mediaEl.off('playing').on('playing', function() {
          $timeout(function() {
            $scope.status.isPlaying = true;
            $scope.background.isPlaying = true;
          });
        });


        $scope.mediaEl.off('pause').on('pause', function() {
          $timeout(function() {
            $scope.status.isPlaying = false;
            $scope.background.isPlaying = false;
          });
        });

        $scope.mediaEl.off('ended').on('ended', function() {

          if($state.params.id === globals.introUID || $state.params.id === 'what_is_quipu'){
            BackgroundAPI.disableSyncedVid();
            BackgroundAPI.updateBackground('library', 'GENERIC');
          }


          $timeout(function() {
            $scope.status.isEnded = true;
            $scope.status.isPlaying = false;
            $scope.background.isPlaying = false;
          });
        });



        $scope.$on('$destroy', function(){
          if(playCountTimeout) $timeout.cancel(playCountTimeout)
        })

      }

    }
  }

})();