/*

  Subtitles

*/
;(function () {

  angular
    .module('angularApp.services')
    .factory('QuipuSubtitleAccess', QuipuSubtitleAccess);

  function QuipuSubtitleAccess($http, CDN, $q, Utilities) {
    return {
      getSubAt: function(inPoint, thread, index) {
        return $q(function(resolve, reject){
          var srt = CDN.srts() + thread;

          // we don’t need to use the php proxy here, faster without
          // $http.get('php/proxy.php?url=' + srt)
          $http.get(srt)
            .success(function(response){
              var outerArray = response.split('\n\n')

              _.each(outerArray, function(o){
                if( o.split('\n')[1]){
                  var rawTime = o.split('\n')[1].split('-->')[0].replace(/:/g,'.').replace(',','.')
                  var cookedTime = Utilities.timeFormat(rawTime)
                  if(cookedTime > inPoint) {
                    resolve(o.split('\n')[2])
                  }
                }
              })
            })

        })
      }
    }
  }

})();