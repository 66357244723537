;(function () {

  angular
    .module('angularApp')
    .service('share', Share);

  function Share(globals){

    var description = {
      en: '272,000 women and 21,000 men were sterilised in the 90’s in Peru. Thousands have claimed this happened without their consent, but until now they have been repeatedly silenced and denied justice. After almost 20 years their voices can finally be heard through this interactive documentary, which connects a free telephone line in Peru to this website.',
      es: '272.000 mujeres y 21.000 hombres fueron esterilizados en los 90 en Perú. Miles han reclamado que esto sucedió sin su consentimiento, pero hasta ahora han sido repetidamente silenciados y se les ha negado justicia. Tras casi 20 años, sus voces finalmente pueden ser escuchadas…',
    }

    window.fbAsyncInit = function() {
      FB.init({
        appId      : '1513641775630194',
        xfbml      : true,
        version    : 'v2.5'
      });
    };

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    return {
      facebook: facebook,
      twitter:  twitter
    }

    // ********************************************************

    function randomImage(){
      var index = Math.ceil( Math.random() * 10 )
      return globals.projectUrl + '/assets/socialsharing/photo'+index+'quipu_'+globals.lang+'.jpg'
    }

    function facebook(permalink){

      var url = permalink ? window.location.href : globals.projectUrl;

      var meta = {
        method:      'feed',
        link:        url,
        picture:     randomImage(),
        caption:     'The Quipu Project',
        description: description[globals.lang] || '',
      }

      console.log('meta', meta);

      var callback = function(response){
        console.log('share successful');
      }

      FB.ui(meta, callback);

      // window.open(
      //   'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(url),
      //   'facebook-share-dialog',
      //   'width=626,height=436'
      // )
    }

    function twitter(permalink){

      var url = permalink ? window.location.href : globals.projectUrl;

      window.open(
        'http://twitter.com/intent/tweet?url='+encodeURIComponent(url),
        'twitter-share-dialog',
        'width=626,height=300'
      )
    }

  }

})();

