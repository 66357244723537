/*

  Router

    using ui-router: https://github.com/angular-ui/ui-router/wiki/

*/
;(function () {

  angular
    .module('angularApp')
    .config(Router)

  function Router($urlRouterProvider, $stateProvider){

    $urlRouterProvider.otherwise('/en/quipu/intro');

    $stateProvider
      .state('quipu', {
        url: '/:lang/quipu',
        templateUrl: 'partials/quipu.html',
        controller: 'quipuCtrl',
        reloadOnSearch: false,
        resolve: {
          loadData: function(data){ return data.fetch() },
          setLang: function($stateParams, globals){ globals.lang = $stateParams.lang },
        },
      })
        // intro state
        .state('quipu.intro', {
          url: '/intro',
          reloadOnSearch: false,
          views: {
            'media': {},
            'content': {
              templateUrl: 'partials/intro-content.html',
              controller: 'introCtrl'
            }
          }
        })
        // listen state
        .state('quipu.listen', {
          url: '/listen/:id?currentTime?view?tag?expanded',
          reloadOnSearch: false,
          views: {
            'media': {
              templateUrl: 'partials/testimony.html',
              controller: 'audioCtrl',
            },
            'content': {
              templateUrl: 'partials/listen-content.html',
            }
          },
          // resolve: {
          //   threadExists: function(AudioAPI, $stateParams){ return AudioAPI.threadExists($stateParams.id) }
          // }
        })
        // record state
        .state('quipu.takeaction', {
          url: '/takeaction',
          reloadOnSearch: false,
          resolve: {},
          views: {
            'media': {
              templateUrl: 'js/modules/record/record.controller.html',
              controller: 'RecordCtrl'
            },
            'content': {}
          }
        })

    // error/unsupported state
    $stateProvider
      .state('unsupported', {
        url: '/:lang/unsupported',
        templateUrl: 'js/modules/error/error.ctrl.html',
        controller: 'errorCtrl',
        resolve: {
          setLang: function($stateParams, globals){ globals.lang = $stateParams.lang }
        }
      })

  }

})();