/*

  Intro Controller

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.controllers')
    .controller('introCtrl', introCtrl)

  function introCtrl($scope, $http, $state, $timeout, $rootScope, globals, progress) {
    $scope.vm = {
      listen: listen,
      lang: $state.params.lang,
      switchLang: switchLang
    }

    var rotateTimeout

    setup()

    // ********************************************************

    function setup(){

      $scope.$on('$destroy', function(){
        $timeout.cancel(rotateTimeout);
      })

      $timeout(function(){ $rootScope.$emit('rotateQuipu', 'random'); })
      rotateQuipu();
    }

    function listen(lang){
      var params = {
        currentTime: 0,
        view: 'thread'
      };

      // // only go to the intro if we haven't gone there before
      // var listened = progress.load();
      // console.log('listened', listened);
      // if(listened.indexOf(globals.introUID) === -1)
      //   params.id = 'intronode';

      // nope, always go to the intro >.<
      params.id = 'intronode';

      if(lang)
        if(lang !== globals.lang)
          params.lang = lang;

      $state.go('quipu.listen', params);
    }

    function switchLang(){
      var other = ( $state.params.lang === 'en' ? 'es' : 'en' );
      $state.go($state.current.name, { lang: other });
    }


    function rotateQuipu(){
      rotateTimeout = $timeout(function(){
        $rootScope.$emit('rotateQuipu', 'random');
        rotateQuipu();
      }, 1000 + Math.random()*2000 )
    }








  }

})();