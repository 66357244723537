/*

  BinaryJS Connector

    binary.upload(blob, meta).then()

*/
;(function() {

  angular
    .module('angularApp')
    .service('binary', Binary);

  function Binary($rootScope, $q, CDN, _, Utilities, bowser){

    return {
      upload: upload,
      test: test
    };

    function test(){
      var serverTest = $q(function(resolve, reject){

        // if(bowser.chrome && bowser.version >= 47) {
        //   reject()
        //   return;
        // }

        var binaryClient = new BinaryClient(CDN.binary);

        binaryClient.on('open', function(){
          binaryClient.send({}, { hello: 'world' });
        })

        binaryClient.on('stream', function(stream){
          stream.on('data', function(data){
            if(data.hello) resolve();
          })
        })
      });

      return Utilities.timeoutPromise(serverTest, 2000);
    }

    function upload(blob, _meta){
      return $q(function(resolve, reject){

        console.log('[binary] connecting to:', CDN.binary);
        var binaryClient = new BinaryClient(CDN.binary);

        var meta = _.extend(_meta, { size: blob.size });

        // when the connection opens, send the file
        binaryClient.on('open', function(){
          console.log('[binary] connected, sending blob (size:' + blob.size +')');
          binaryClient.send(blob, meta);
        });

        // when we receive data back from the server
        binaryClient.on('stream', function(stream){

          stream.on('data', function(data){
            if( data.complete )
              resolve();
            else if( data.error )
              reject( data.error );
            else if( data.uploadProgress )
              $rootScope.$emit('binary.uploadProgress', data.uploadProgress);
          });

        });

      });
    }
  }
})();