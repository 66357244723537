/*

  Google Analytics

*/
;(function () {

  angular
    .module('angularApp')
    .factory('ga', GoogleAnalytics)

  function GoogleAnalytics($q, $http, $state, globals, dataStore, AudioAPI, BackgroundAPI){

    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

    ga('create', 'UA-44960344-8',  {name:'a'}); // chaka
    ga('create', 'UA-11990834-11', {name:'b'}); // helios

    return {
      pageview: pageview
    }

    function pageview(){
      var page = $state.current.name
      page = page.replace('.', '/')

      if($state.params.id) page += '/'+$state.params.id

      var params = {
        page: page,
        dimension1: globals.lang
      }

      ga('a.send', 'pageview', params);
      ga('b.send', 'pageview', params);
    }

  }

})();