/*

  Help Overlay

    (part of the audio player)

*/
(function () {

  angular
    .module('angularApp.directives')
    .directive('helpOverlay', helpOverlay);

  function helpOverlay($q, $rootScope, $timeout, globals, AudioAPI){
    return {
      restrict: 'E',
      replace: true,
      scope: {},
      templateUrl: 'js/modules/help/help.template.html',
      link: function(scope, element, attrs){

        scope.vm = {
          show: false,
          close: closeHelp
        }

        scope.$parent.showHelp = showHelp;

        function showHelp(){
          return $q(function(resolve, reject){
            globals.helpShown = true;
            scope.vm.show = true;

            $rootScope.$emit('pause')

            $('.audio__timelines').addClass('audio__timelines--hover');

            var watcher = scope.$watch('vm.show', function(show){
              if(!show){ resolve(); watcher(); }
            })

          })
        }


        function closeHelp(){
          $timeout(function(){ scope.vm.show = false; })
          $('.audio__timelines').removeClass('audio__timelines--hover');

        }

        var cleanup = $rootScope.$on('showHelp', showHelp);
        scope.$on('$destroy', cleanup);
      }
    }

  }


})();