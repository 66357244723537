/*

  Utilities

*/
;(function () {
  'use strict';

  angular.module('angularApp.services')
    .factory('Utilities', function($timeout, $q) {

      return {
        timeFormat: timeFormat,
        safeString: safeString,
        timeoutPromise: timeoutPromise,
      };

      /*

        mm:ss:ms -> s
        01:05:20 -> 65.2

      */
      function timeFormat(string) {
        var timeArray = string.split('.');

        var seconds = parseInt(timeArray[timeArray.length - 2], 10) || 0;
        var minutes = parseInt(timeArray[timeArray.length - 3], 10) || 0;

        var output = ((minutes*60) + seconds) + '.' + timeArray[timeArray.length-1];

        return +(parseFloat(output).toFixed(2));
      }

      function safeString(string) {
        return string.replace(/\s+/g, '-').toLowerCase();
      }

      /*

        Time out a promise if it takes too long

      */
      function timeoutPromise(promise, timeout){
        return $q(function(resolve, reject){
          promise.then(function(){ resolve(); });
          $timeout(function(){ reject() }, timeout);
        });
      }


    });
})();