/*

  Background

    Shows ambient background videos from a media library.

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.directives')
    .directive('background', background);

  function background($window, $q, $timeout, $location, $filter, CDN, globals, resize, AudioAPI, BackgroundAPI) {
    return {
      restrict: 'AE',
      replace: true,
      templateUrl: 'js/modules/background/background.template.html',
      scope: {},
      controller: function($scope, $element) {
        $scope.api = this.api = BackgroundAPI;
        $scope.viewData = $scope.$parent.viewData
      },
      link: function(scope, elem, attr, ctrl) {

        var duration = 2500

        scope.players = {};
        scope.currentBackground   = null;
        scope.backgroundClass     = null;
        scope.api.captionText     = ''
        scope.api.descriptionText = ''
        scope.api.showCaption     = false
        scope.api.showDescription = false

        var mediaEl = elem.find('.background__media');

        var switchBackground = {

          none: function(){
            scope.currentBackground = undefined;
          },

          photo: function(file) {
            var photoID = 'photo-' + file.split('.')[0];
            mediaEl.append('<div id="' + photoID + '" class="background__photo"></div>');
            var imgEl = mediaEl.find('#' + photoID);
            imgEl.css('background-image', 'url(' + CDN.image + file + ')');

            scope.currentBackground = photoID;
            scope.backgroundClass = 'background--photo';
          },

          video: function(file, loop) {
            var split = file.split('.');
            var playerID = 'video-' + split[0];

            var videoEl = document.createElement('video');

            videoEl.muted = true;
            videoEl.autoplay = true;
            videoEl.setAttribute('class', 'background__video');
            videoEl.setAttribute('id', playerID);
            if(loop) videoEl.loop = true;

            mediaEl.append(videoEl)

            var filePath = CDN.video + file;
            if( globals.videoType === 'mp4' ){
              filePath = CDN.video + split[0] + '.mp4'
            }
            videoEl.src = filePath;
            videoEl.load()

            var timeupdate = function(){
              if( videoEl.currentTime >= videoEl.duration - 4 ){
                videoEl.removeEventListener('timeupdate', timeupdate);
                $timeout(BackgroundAPI.nextInLibrary);
              }
            }

            var canplaythrough = function() {
              videoEl.removeEventListener('canplaythrough', canplaythrough);
              videoEl.addEventListener('timeupdate', timeupdate, false);
              // videoEl.play()

              var p = videoEl.play();
              if (p) {
                p.then(function () {
                  // Autoplay available
                }).catch(function (error) {
                  // Playback prevented
                  console.error(error)
                })
              }

              scope.currentBackground = playerID;
              scope.backgroundClass = 'background--video';

              // if there are other videos, remove them
              var videos = $('.background__video')
              videos.each(function(index, el){
                if( el.id !=='syncedvid' && el.id !== playerID )
                  $(el).fadeOut(duration, function(){
                    $(el).remove()
                  });
              })
            }

            videoEl.addEventListener('canplaythrough', canplaythrough, false);

            // using timeupdate instead for a more seamless transition
            // videoEl.addEventListener('ended', function() {
            //   $timeout(BackgroundAPI.nextInLibrary);
            // })
          }
        };


        scope.$watch('currentBackground', function(newBg, oldBg) {

          if (newBg === oldBg) return

          var fadeTime = duration;
          var newEl = newBg ? mediaEl.find('#' + newBg) : null;
          var oldEl = oldBg ? mediaEl.find('#' + oldBg) : null;

          if (newEl) {
            newEl.fadeIn(fadeTime);
          }

          if (oldEl) {
            oldEl.fadeOut(fadeTime, function() {
              if (scope.players[this.id]) {
                scope.players[this.id] = null;
              }
              oldEl.remove();
            });
          }
        });



        scope.$watch('api.mediaItem', function(newItem){

          if(!newItem){
            scope.currentBackground = null;
            return;
          }

          if(switchBackground[newItem.MEDIA_TYPE]) {
            switchBackground[newItem.MEDIA_TYPE](newItem.FILE, (newItem.loop || false));
          }

        });



        scope.$watch('api.isPlaying', function(newVal, oldVal) {

          if (newVal === oldVal || !scope.players[scope.currentBackground]) { return; }

          if (newVal) scope.players[scope.currentBackground].playVideo();
          else        scope.players[scope.currentBackground].pauseVideo();
        });
      }
    }
  }

})();