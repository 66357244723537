/*

  Audio Recorder

    should be cross-platform:
      Firefox -> MediaRecorder API
      Chrome  -> https://github.com/mattdiamond/Recorderjs
        (MediaRecorder API in development https://www.chromestatus.com/features/5929649028726784)

    requires: https://github.com/mattdiamond/Recorderjs

    todo:
      - feature detection for Recorderjs
      - MediaRecorder API

*/
;(function () {

  // webkit shim
  window.AudioContext = window.AudioContext || window.webkitAudioContext;
  navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
  window.URL = window.URL || window.webkitURL || window.mozURL || window.msURL;

  angular
    .module('angularApp')
    .factory('recorder', RecorderFactory);

  function RecorderFactory($q){

    // feature detection
    var detect = {
      mediaRecorder: !!(window.MediaRecorder),
    }

    var audioRecorder, audioContext, mediaStreamSource, mediaStream
    var startTime, endTime

    var exports = {

      init: initRecording,
      destroy: destroy,

      start: startRecording,
      stop: stopRecording,

      clear: clearRecording,

      exportBlob: exportBlob,
    }

    // ********************************************************

    function initRecording(){
      return getUserMedia()
        .then(function(mediaStream){
          return buildRecorder(mediaStream)
        })
    }

    function getUserMedia(){
      return $q(function(resolve, reject){

        var constraints = {
          video: false,
          audio: {
            mandatory: {
              // 'googEchoCancellation': false,
              // 'googAutoGainControl':  false,
              // 'googNoiseSuppression': false,
              // 'googHighpassFilter':   false
            }
          }
        }

        navigator.getUserMedia(constraints, success, reject);

        function success(_mediaStream){
          mediaStream = _mediaStream
          resolve(_mediaStream)
        }

      })
    }

    function destroy(){
      if(mediaStream){

        // chrome 45+
        if( mediaStream.active ){
          var tracks = mediaStream.getTracks();
          tracks.map(function(track){
            track.stop();
          })
        }

        // chrome >45
        else if(mediaStream.stop){
          mediaStream.stop()
        }
      }

      if(audioRecorder){
        audioRecorder.clear()
        audioRecorder = null;
      }
    }

    function buildRecorder(stream){
      return $q(function(resolve, reject){

        audioContext = new AudioContext();
        mediaStreamSource = audioContext.createMediaStreamSource(stream);

        audioRecorder = new Recorder(mediaStreamSource, {
          workerPath: window.location.origin + window.location.pathname + 'components/Recorderjs/recorderWorker.js'
        });

        resolve()
      })
    }

    function startRecording(){
      audioRecorder.record();
    }

    function stopRecording(){
      audioRecorder.stop();
    }

    function clearRecording(){
      audioRecorder.clear();
    }


    // function testObjectUrl( url ){
    //   return $q(function(resolve, reject){

    //     // return $timeout(function(){}, 500)
    //     //   .then(function(){
    //     //     return $http.get(url).then(resolve, reject)
    //     //   })

    //     setTimeout(function(){
    //       var httpTest = new XMLHttpRequest();
    //       httpTest.open( 'GET', url, false );
    //       httpTest.send();

    //       console.log('test', url, httpTest.status)

    //       if( httpTest.status === 404 ) reject();
    //       else                          resolve();
    //     }, 500)
    //   })
    // }

    function exportBlob(){
      return $q(function(resolve, reject){
        audioRecorder.exportWAV( function(blob) {

          generateZip(blob)
            .then(function(zip){
              var wavUrl = URL.createObjectURL( blob );
              resolve({
                zip: zip,
                url: wavUrl,
                blob: blob
              })
            })

        });
      })
    }

    function generateZip(blob){
      return $q(function(resolve, reject){
        var zip = new JSZip()
        var audioReader = new FileReader()

        audioReader.addEventListener('loadend',function(){
          zip.file('input.wav', audioReader.result)
          zip = zip.generate({ type: 'blob', compression: 'DEFLATE' })
          resolve(zip)
        })

        audioReader.readAsArrayBuffer(blob)
      })
    }

    return exports;
  }


})();