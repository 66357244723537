/*

  Side nav menu (tags, etc)

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.directives')
    .directive('navMenu', navMenu);

  function navMenu($rootScope, $state, $location, $filter, $timeout, CDN, resize, AudioAPI, Utilities, QuipuAPI) {
    return {
      restrict: 'E',
      replace: true,
      scope: {},
      templateUrl: 'js/modules/navmenu/navmenu.template.html',

      controller: function($scope, $element) {
        $scope.audioAPI = AudioAPI;
        $scope.viewData = {
          currentTag: AudioAPI.currentTag,
          tags: [
            {'id': 'campaign',  'prettyName': $filter('localize')('campaign')  },
            {'id': 'operation', 'prettyName': $filter('localize')('operation') },
            {'id': 'lifeafter', 'prettyName': $filter('localize')('lifeafter') },
            {'id': 'justice',   'prettyName': $filter('localize')('justice')   },
          ]
        }
      },

      link: function(scope, elem, attr, ctrl) {

        scope.appData = scope.$parent.appData

        scope.vm = {
          manageTag: manageTag,
          goAbout: goAbout,
          goIntro: goIntro,
          help: help
        }

        function manageTag(_tag){

          if(AudioAPI.currentTag === _tag) return
          scope.audioAPI.currentTag = _tag;

          if( !_tag ){
            $state.go('quipu.listen', { tag: undefined, view: 'thread' })
          } else if( _tag === 'responses' ){
            $state.go('quipu.listen', { id: AudioAPI.find.firstResponse(), tag: 'responses', view: 'thread' })
          } else {

            var next = scope.audioAPI.theme.next(_tag);

            $state.go('quipu.listen', {
              id: next.thread.UID,
              currentTime: next.region.timeIn || 0,
              tag: _tag,
              view: 'knot'
            });

          }

        }

        function help(){
          $rootScope.$emit('audio.pause');
          $rootScope.$emit('showHelp');
        }


        function goAbout(){
          $state.go('quipu.listen', {
            id: 'what_is_quipu',
            currentTime: 0,
            view: 'thread'
          })
        }

        function goIntro(){
          $state.go('quipu.listen', {
            id: 'intronode',
            currentTime: 0,
            view: 'thread'
          })
        }

      }
    }
  }

})();