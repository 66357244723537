/*

  Progress

    save listened state for tracks, and tell the Drupal server that a user has listened.

*/

;(function () {

  angular
    .module('angularApp')
    .factory('progress', progress)

  function progress($http, storage, globals){

    return {
      count: count,
      load:  load
    }

    function count(uid){

      // 1. tell the Drupal CMS to track a listen
      // var alreadyTracked = storage.get('quipu.alreadyTracked')

      // if(!alreadyTracked){

      // TEMP: track a listen every time somebody views the intro
      if (uid === globals.introUID) {
        // phoneline is dead.
        // $http.get('https://phoneline.quipu-project.com/quipu_data_pipe/listen_count/null/null/null')
          // .then(function(res){
            // if(res.data) 
              storage.set('quipu.alreadyTracked', true);
          // })

        // and reset the listened state
        storage.set('quipu.listened', []);

        console.log('LISTENED');
      }

      // 2. store the listened state of this track in local storage
      var listened = storage.get('quipu.listened')
      if(!listened) listened = [];
      listened.push(uid);
      storage.set('quipu.listened', listened);

    }

    function load(){
      return storage.get('quipu.listened') || [];
    }

  }

})();