/*

  string tokenizer
  https://github.com/wblankenship/varstring

  usage:
    token.ize( 'transform({x},{y})', { x: 0, y: 20 } ) -> 'transform(0,20)'

*/

(function () {

  angular
    .module('angularApp.services')
    .service('token', Tokenizer)

  function Tokenizer(){
    function parse(string, params) {
      if(params instanceof Object) {
        //do nothing
      }
      else if(!(params instanceof Array)) {
        params = Array.prototype.slice.call(arguments)
        string = params.shift()
      }

      Object.keys(params).forEach(function(v) {
        string = string.replace(new RegExp(escapeRegExp("{"+v+"}"),'g'),params[v])
      })
      return string
    }

    function escapeRegExp(s) {
      return s.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
    }

    return { ize: parse }
  }

})();