/*

  Header Menu

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.directives')
    .directive('header', header);

  function header($rootScope, $state, $timeout, $location, CDN, AudioAPI, resize, globals, share) {
    return {
      restrict: 'E',
      replace: true,
      scope: {},
      templateUrl: 'js/modules/header/header.template.html',
      link: function(scope, elem, attr, controller) {

        scope.appData = scope.$parent.appData;

        scope.vm = {
          backToIntro: backToIntro,

          about: about,
          takeaction: takeaction,
          resources: resources,
          credits: credits,
          closeModal: closeModal,

          lang: lang,
          otherLang: '',

          facebook: share.facebook,
          twitter: share.twitter,
          mailto: [
            'mailto:',
            '?subject=', encodeURIComponent('Quipu Project'),
            '&body=', encodeURIComponent(location.href)
          ].join(''),

          modal: null,

          toggleMute: toggleMute,
          isMuted: false
        }

        // switch languages
        function lang(){
          var other = ( $state.params.lang === 'en' ? 'es' : 'en' )
          $state.go($state.current.name, {
            lang: other,
            currentTime: AudioAPI.currentTime
          });
        }

        scope.vm.currentLang = $state.params.lang
        scope.vm.otherLang = ( $state.params.lang === 'en' ? 'Español' : 'English' )

        function toggleMute(){
          scope.vm.isMuted ? $rootScope.$emit('unmute') : $rootScope.$emit('mute');
          // console.log(scope.vm.isMuted ? 'unmute': 'mute');
          scope.vm.isMuted = !scope.vm.isMuted;
          AudioAPI.muted = scope.vm.isMuted;
        }

        function about(){
          scope.vm.modal = 'about'
          $rootScope.$emit('audio.pause')

        }

        function resources(){
          scope.vm.modal = 'resources'
          $rootScope.$emit('audio.pause')
        }

        function credits(){
          scope.vm.modal = 'credits'
          $rootScope.$emit('audio.pause')
        }

        function takeaction(){
          $rootScope.$emit('audio.pause')
          $state.go('quipu.takeaction', {});
        }

        function closeModal(){
          scope.vm.modal = ''
        }

        function backToIntro(){
          $state.go('quipu.intro', {});
        }

      }
    }
  }
})();
