/*

  Recorder Controller

*/

;(function () {

  angular
    .module('angularApp')
    .controller('RecordCtrl', RecordCtrl);

  function RecordCtrl($scope, $state, $http, $q, $sce, $timeout, bowser, globals, AudioAPI, BackgroundAPI, recorder, dataStore, frameRunner, timeFormat, binary){

    $scope.vm = {

      count: {
        listen: dataStore.count.listen || 0,
        record: dataStore.count.record || 0,
      },

      status: {
        init: false,
        recording: false,
        playing: false,
        disabled: true,
      },

      formData: {},
      formState: 'entering', // enter, upload, thanks

      stage: 'intro', // intro, ready, recording, rendering, preview
      text: 'intro', // intro, thanks

      backToQuipu: backToQuipu,

      destroyRecording: destroyRecording,
      startRecording:   startRecording,
      stopRecording:    stopRecording,

      preview: previewPlayPause,
      startOver: startOver,
      submit: submit,
      uploadAgain: uploadAgain,

      cancelForm: cancelForm,
      submitForm: submitForm,
    }

    var maxLength = 60 * 3; // 3 minutes
    var time = 0;

    var audio, blob, zip;
    var recordThread = document.querySelector('.js-record-thread');
    var timeCode     = document.querySelector('.js-timecode');

    var startTime;

    setup();

    // ********************************************************

    function setup(){
      $scope.appData.loaded = true; // dismiss the loading spinner
      BackgroundAPI.updateBackground('library', 'GENERIC');

      audio = document.createElement('audio');
      audio.addEventListener('ended', previewEnded, false);

      // test that the recording server is available,
      // so we don’t disappoint people
      binary.test()
        .then(
          function(){
            console.log('binary test SUCCESS');

            if( !bowser.safari && !bowser.msie )
              $scope.vm.status.disabled = false;
          },
          function(){
            $scope.vm.status.disabled = true;
            console.log('binary test FAIL');
          }
        )

      // test that getusermedia is supported,
      // so we don't disappoint people

    }

    function backToQuipu(){
      $state.go('quipu.listen')
    }

    /*

      Record

    */

    function getUserMedia(){
      recorder.init()
        .then(function(){
          $scope.vm.status.init = true;
          $scope.vm.text = 'record';

          $scope.vm.stage = 'ready';
        });
    }

    function destroyRecording(){
      if($scope.vm.status.recording)
        recorder.stop();

      recorder.destroy();

      $scope.vm.status.init = false;
      $scope.vm.text  = 'intro';
      $scope.vm.status.preview = false;
    }

    function startRecording(){
      if(!$scope.vm.status.init){
        getUserMedia(); return;
      }

      $scope.vm.stage = 'recording';
      recorder.start();
      startTime = (new Date()).getTime();

      time = 0;
      $scope.vm.timecode = timeFormat(0);
      frameRunner.add({f: updateRecording, id: 'updateRecording', type: 'everyFrame' });
    }

    function updateRecording(){
      time = ((new Date()).getTime() - startTime) / 1000;
      // $timeout(function(){ $scope.vm.timecode = timeFormat(time); })

      recordThread.style.width = (time/maxLength * 100) + '%'
    }

    function stopRecording(){
      $scope.vm.stage = 'rendering';
      recorder.stop()
      frameRunner.remove({id: 'updateRecording'});

      recordThread.style.width = '0%';

      recorder.exportBlob()
        .then(function(data){
          blob = data.blob;
          zip  = data.zip;
          audio.src = data.url;
          $scope.vm.stage = 'preview';
          $scope.vm.text  = 'thanks';
        })
    }





    /*

      Preview

    */
    function previewPlayPause(){
      if(!audio.src) return;

      if(audio.paused){
        audio.play();
        frameRunner.add({id: 'updatePreview', f: updatePreview})
      } else {
        audio.pause();
        frameRunner.remove({id: 'updatePreview'})
      }

      $scope.vm.status.playing = !audio.paused
    }

    function updatePreview(){
      if(audio.readyState < 4) return;
      // $timeout(function(){ $scope.vm.timecode = timeFormat(time); })

      recordThread.style.width = (audio.currentTime/audio.duration * 100) + '%'
    }


    function previewEnded(){
      $timeout(function(){ $scope.vm.status.playing = false; })
      frameRunner.remove({id: 'updatePreview'})
    }

    function startOver(){
      if(!audio.paused) previewPlayPause();

      recorder.clear();
      audio.src = '';
      // $scope.vm.status.init = false;
      recordThread.style.width = '0%';

      $timeout(function(){
        $scope.vm.stage = 'ready';
        $scope.vm.text  = 'record';
      }, 500);

    }




    /*

      Add recording to the quipu

    */

    function submit(){
      if(!audio.paused) previewPlayPause();
      $scope.vm.submitModal = true;
      $scope.vm.formState = 'entry'
    }

    function cancelForm(){
      $scope.vm.submitModal = false;
    }


    function submitForm(valid){
      if(!valid) return;

      $scope.vm.formState = 'upload'
      binary.upload(zip, $scope.vm.formData)
        .then(function(){ $scope.vm.formState = 'thanks' })

    }

    // for debugging
    function uploadAgain(){
      binary.upload(zip, $scope.vm.formData)
        .then(function(){
          $scope.vm.formState = 'thanks'
        })
    }




    /*

      Cleanup

    */

    $scope.$on('$destroy', function(){
      destroyRecording();

      frameRunner.remove({id: 'updatePreview'});
      frameRunner.remove({id: 'updateRecording'});

      if(audio.src) audio.pause();
      audio = null;
    });


  }

})();