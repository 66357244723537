/*

  Localize filter

    usage: <div>{{ 'string'|localize }}</div>

    Depends on loadData service (via dataStore),
    but so does the rest of the experience so
    this’ll likely never be without data.

*/

;(function () {

  angular
    .module('angularApp')
    .filter('localize', localize)

  function localize(data, globals, dataStore, $sce){
    return function(input, force){
      var input = input || '';
      var lang = force ? force : globals.lang;
      if(dataStore.translations[input]){
        var text = dataStore.translations[input][lang];
        text = text.replace( /\n/g, '<br>' );
        return $sce.trustAsHtml(text);
      } else {
        return '';
      }
    }
  }

})();