/*

  not using this any more

  Audio Player Controls

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.directives')
    .directive('audioMeta', audioMeta);

  function audioMeta($timeout, $location, CDN, resize) {
    return {
      restrict: 'AE',
      replace: true,
      require: '^audioPlayer',
      templateUrl: 'js/modules/audio-player/audio-meta.template.html',
      controller: function($scope, $element, AudioAPI) {
        $scope.api = $scope.$parent.api = this.api = AudioAPI;
      },
      link: function(scope, elem, attr, audioCtrl) {

        scope.togglePlay = function() {
          if (audioCtrl.status.isPlaying) audioCtrl.pause();
          else                            audioCtrl.play();
        };

        scope.toggleMute = function() {
          if (scope.api.muted) audioCtrl.unmute();
          else                 audioCtrl.mute();
        }

        scope.$on('updateMeta', function(event, atts) {
          scope.audioName = atts.name;
        });

      }
    }
  }
})();
