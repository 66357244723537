'use strict';

angular.module('angularApp')
	.constant('NAME', 'QUIPU PROJECT')

  .value('globals', {
    lang: 'en',
    projectUrl: 'https://interactive.quipu-project.com',
    introUID: 'intronode',
    helpShown: false,
    breakpoints: {
      'short': 750
    },

    videoType: (function(){
      var el = document.createElement('video');
      var webm = !!(el.canPlayType && el.canPlayType('video/webm; codecs="vp8, vorbis"').replace(/no/, ''));
      var mp4  = !!(el.canPlayType && el.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"').replace(/no/, ''));

      if( webm )     return 'webm';
      else if( mp4 ) return 'mp4';
    })(),
  })

  .value('dataStore', {})

  .factory('CDN', function(globals) {
    var baseUrl = 'https://interactive.quipu-project.com/cdn/'

    return {
      cdn: baseUrl,
      srts: function(lang) { return baseUrl + 'srt/' + globals.lang + '/'; },
      audio:        baseUrl,
      ambientAudio: baseUrl + 'additional_content/',
      baseUrl:      baseUrl,
      image:        baseUrl + 'libraries/',
      video:        baseUrl + 'libraries/',
      binary:      'wss://node.quipu-project.com/ws',
    }
  });