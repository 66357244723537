/*

  JSON data loader

    data.fetch().then(function(daaaattaaaa){  })

*/
;(function () {

  angular
    .module('angularApp')
    .factory('data', data)

  function data($q, $http, dataStore, AudioAPI, BackgroundAPI){

    var localData;

    return {
      fetch: fetch
    }

    // ********************************************************

    function fetch(){
      return $q(function(resolve, reject){

        if(localData){

          resolve(localData);

        } else {

          $http.get( 'data.json' + '?nocache='+(new Date()).getTime() )
            .then(function(res){
              console.log('DATA %o', res.data);

              dataStore.translations = res.data.translations;
              dataStore.threads      = res.data.threads;
              dataStore.captions     = res.data.captions;
              dataStore.libraries    = res.data.libraries;
              dataStore.count        = res.data.count;

              dataStore.testimonyCount = 0
              for (var i = 0; i < res.data.threads.length; i++)
                if(res.data.threads[i].Role === 'testimony') dataStore.testimonyCount += 1

              AudioAPI.eventData      = res.data.threads;
              AudioAPI.captionData    = res.data.captions;
              BackgroundAPI.libraries = res.data.libraries;

              localData = res.data;
              resolve(localData);
            }, function(res){
              console.log('DATA load error');
              reject('data load');
            })
        }

      })
    }

  }

})();