/*

  Ambient Audio Player

  put this in your controller:
  $scope.$watchCollection('AudioAPI.ambient', ambientAudio.update);

  audioAPI.ambient = [ 'track1', 'track2' ];

*/

;(function () {

  angular
    .module('angularApp')
    .factory('ambientAudio', ambientAudio);

  function ambientAudio($rootScope, $q, AudioAPI, mix, storage, CDN){

    setup();

    return {
      update: update
    };

    // ********************************************************

    function setup(){
      $rootScope.$on('mute', mute);
      $rootScope.$on('unmute', unmute);

      if(storage.get('muted')) mute();
    }

    function update(newTracks){
      if(!newTracks) return;

      console.log('ambient audio:', newTracks);

      var defaultTrackOptions = {
        looping: true,
        autoplay: true,
        sourceMode: 'element',
        gain: 0.5
      };

      if(storage.get('quipuMute')) defaultTrackOptions.gain = 0; // debug hack

      var tracksToAdd = [];
      var tracksNamesToRemove = [];

      // tracks to add
      for (var i = newTracks.length - 1; i >= 0; i--) {
        if(mix.getTrack(newTracks[i]))
          continue;
        else
          tracksToAdd.push(newTracks[i]);
      }

      // tracks to remove
      for (var j = mix.tracks.length - 1; j >= 0; j--) {
        if( AudioAPI.ambient.indexOf( mix.tracks[j].name ) !== -1 )
          continue;
        // // don’t remove tracks that start with 'constant'
        // else if(mix.tracks[j].name.indexOf('constant') === 0)
        //   continue
        else
          tracksNamesToRemove.push(mix.tracks[j].name);
      }

      // fade out and remove old tracks
      tracksNamesToRemove.map(function(trackName){
        var track = mix.getTrack(trackName);
        if(track){
          track.tweenGain(0, 1000)
            .then(function(track){
              mix.removeTrack(track);
            });
        }
      });

      // add new track
      tracksToAdd.map(function(trackName){
        var opts = _.extend(defaultTrackOptions, { source: CDN.ambientAudio + trackName });
        mix.createTrack( trackName, opts );
        var track = mix.getTrack( trackName );
        if(!track){
          track.tweenGain((storage.get('quipuMute') ? 0 : 1), 4000);
        }
      });

    }

    function mute(){
      for (var i = 0; i < mix.tracks.length; i++) {
        mix.tracks[i].gain(0);
      }
    }

    function unmute(){
      for (var i = 0; i < mix.tracks.length; i++) {
        mix.tracks[i].gain(0.5);
      }
    }


  }

})();