/*

  Quipu SVG

    The quipu visualization.

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.directives')
    .directive('quipuSvg', quipuSvg);

  function quipuSvg(
    $rootScope, $q, $window, $timeout, $state, $stateParams, $compile,
    d3, data, resize, storage, token, Utilities, QuipuSubtitleAccess, frameRunner,
    globals, BackgroundAPI, QuipuAPI, AudioAPI, progress
  ){
    return {
      restrict: 'AE',
      replace: true,
      scope: {},

      template: '<svg ng-hide="appData.hideInterface" class="quipu animate" width="700" height="700" viewbox="0 0 700 700"></svg>',

      controller: function($scope) {
        $scope.api = QuipuAPI;
        $scope.audioApi = this.audioApi = AudioAPI;
      },

      link: function( scope, $element, attrs, ctrl, audioCtrl ) {

        scope.appData  = scope.$parent.$parent.appData;
        scope.viewData = scope.$parent.viewData;

        var mouseX = 0, mouseY = 0;

        var settings = {
          width:  resize.windowWidth,
          height: resize.windowHeight,
          dim:    resize.windowHeight,
          r:      resize.windowHeight/4,
          x: 350,
          setup:  false,
          currentTag: null,
          transitionSpeed: 800
        };

        var crossThreadData = [];
        var crossThreadDataLength = 0;

        var elements = {
          svg: d3.select($element[0]),
          quipuRoot: null,
          nav: null,
          arc: null,
          threads: null,
        };

        var currentQuipuIndex = null;
        var percentAdded = 0;

        var hideThreads = true;
        var showingThreads = false; // are we currently revealing threads?
        var showArray = [];
        var threadsPerSecond;
        var showThreadsListeners = [];

        scope.quipuData = [];
        scope.viewData.quipuData = scope.quipuData;

        var paths = {
          theme: {
            knots: [],
            data:  [],
            path: null,
            playheadIndex: 0, // which knot is the playhead?
          },
          history: {
            knots: [],
            ids:   [],
            data:  [],
            path: null,
            changed: false
          }
        }

        var line = d3.svg.line()
                     .interpolate('cardinal')
                     .tension(0.5)
                     .x(function(d) { return d.x; })
                     .y(function(d) { return d.y; })

        setup();

        /*

          Initial Setup

        */
        function setup(){
          settings = calculateDimensions(settings);

          buildQuipu(AudioAPI.eventData);

          frameRunner.add({ id: 'updatePaths', f: updatePaths });

          // load history
          var historyLine = storage.get('quipu.historyline')
          // if(historyLine){
          //   console.log(historyLine);
          // }

        }

        // t: current time, b: beginning value, c: change in value, d: duration
        function easeInOutQuad(t, b, c, d) {
          if ((t/=d/2) < 1) return c/2*t*t + b;
          return -c/2 * ((--t)*(t-2) - 1) + b;
        }


        /*

          ######  ##   ## #### ##    ######
          ##   ## ##   ##  ##  ##    ##   ##
          ######  ##   ##  ##  ##    ##   ##
          ##   ## ##   ##  ##  ##    ##   ##
          ######   #####  #### ##### ######

        */
        function buildQuipu(_data) {

          // create base elements

          elements.svg
            .attr('width',  settings.width)
            .attr('height', settings.height)
            .attr('viewBox', '0 0 '+settings.width+' '+settings.height);

          elements.quipuRoot = elements.svg.append('g')
            .attr('class', 'quipu_root')

          positionQuipu();

          // resize the quipu arc
          var arc = d3.svg.arc()
            .innerRadius(settings.r - 2)
            .outerRadius(settings.r - 3)
            .startAngle(0)
            .endAngle(180 * (Math.PI/180));

          elements.arc = elements.quipuRoot.append('path')
            .attr('class', 'neckring')
            .attr('fill',  'black')
            .attr("d", arc)

          // set up the data

          _data.map(function(o, i){
            var role = o.Role
            if(!role || role == '') role = 'none'
            scope.quipuData.push({
              'index':  i,
              'id':     o.UID,
              'name':   o.Name,
              'class':  role,
              'length': parseInt(o.Length),
              'srt':    o.SRT,
              'audio':  o.Filename,
              'events': o.events
            });
          })

          var nodes = elements.quipuRoot.selectAll('g.quipu__node');
          var data = nodes.data(scope.quipuData);

          var newNodes = data.enter();

          // Create the quipu threads,
          // using two nested groups so we can transition
          // rotation separately from transformation
          newNodes

            // append outer group, .quipu__node, used for rotating the node
            .append('g')
              .attr('id',function(d){return 'node__' + d.id})
              .attr('class',function(d){return 'quipu__node ' + d.class})
              .attr('ng-class',function(d){
                return "{ 'collapse' : viewData.nav.curcat !== '"+ d.class +"' && viewData.nav.curcat !== 'all'}";
              })
              .attr('transform', 'rotate(0)')
              .style('opacity', 0)

              // append inner group, .quipu__node__inner, used for translating the node
              .append('g')
                .classed('quipu__node__inner', true)
                .attr('transform', token.ize('translate({x},{y})', {x:0, y: settings.r}))

                // append thread line
                .append('line')
                  .attr('class', function(d){ return 'quipu__thread ' + d.class })
                  .attr('x1', 0)
                  .attr("y1", 0)
                  .attr("y2", function(d){ return d.length })

                  .on('click', goToThread);

          // cache this selector so we can rotate nodes later
          elements.threads = elements.quipuRoot.selectAll('g.quipu__node');

          // Create the knots on the threads
          elements.threadsInner = elements.quipuRoot.selectAll('g.quipu__node__inner')
            .each(function(d) {

              var thread = d3.select(this);
              var threadDatum = d;

              // create root knot
              thread.append('circle')
                .classed('quipu__knot', true)
                .classed('quipu__knot--root',     function(d){ return !!(d.class !== 'response') })
                .classed('quipu__knot--response', function(d){ return !!(d.class === 'response') })
                .attr('r', 6)
                .attr('cy', -2)
                .attr('fill',   'black')
                .attr('stroke', 'black')
                .on('click', function(d){
                  addHistoryKnot(d.id, this);
                  goToThread(d);
                })
                .on('mouseover', function(d){ thread.classed('quipu__node--hover', true)  })
                .on('mouseout',  function(d){ thread.classed('quipu__node--hover', false) })

              // create event knots
              _.each(d.events, function(_event, index){
                var thatInner = d.events[index]
                if(_event.isKnot === 'yes'){

                  var timeIn = Utilities.timeFormat(_event.time_in);
                  thatInner.timeIn = timeIn;

                  thread.append('circle')
                    .attr('r', function(d){ return _event.Tag === 'presentation' ? 0 : 5 })
                    .attr('class','quipu__knot quipu__knot--' + Utilities.safeString(_event.Tag))
                    .attr('cy', timeIn + 15)
                    .attr('cx', -1)
                    .on('click', function(d){
                      addHistoryKnot(d.id, this);

                      $state.go('quipu.listen', {
                        id: d.id,
                        currentTime: timeIn,
                        view: (_event.Tag === $state.params.tag ) ? 'knot' : 'thread'
                      })
                    })
                }
              });
            })

          updateListenedStatus();
          changeTheme(scope.audioApi.currentTag);
        };


        function goToThread(d){
          scope.audioApi.quipuReady = false;
          $state.go('quipu.listen', { id: d.id, currentTime: 0, view: 'thread' })
        }



        function updateListenedStatus(){
          var listenedArray = progress.load()

          listenedArray.map(function(uid){
            d3.select('#node__'+uid)
              .classed('quipu__node--listened', true);
          })

        }




        /*

          #####   ###### ###### ##### ###### ######
          ##  ## ##    ##  ##  ##   ##  ##   ##
          #####  ##    ##  ##  #######  ##   #####
          ##  ## ##    ##  ##  ##   ##  ##   ##
          ##  ##  ######   ##  ##   ##  ##   ######

          rotate the quipu to focus on a specific knot,
          or transition the knots off the quipu by calling
          without specifying a target.

        */

        $rootScope.$on('rotateQuipu', function(e, arg){ rotateQuipu(arg); })

        function rotateQuipu(target, showThread){
          return $q(function(resolve, reject){
            if(!target && target !== 0) target = false;
            settings.transitionSpeed = 1200;

            var fullArray = scope.quipuData;
            var threadOpacity = (target === false || hideThreads) ? 0 : 1;

            // if we should show a single thread (and nothing else)
            if(typeof showThread !== 'undefined'){
              if(fullArray[showThread]){
                d3.select('#node__' + fullArray[showThread].id)
                  .transition()
                  .duration(settings.transitionSpeed)
                  .style('opacity', 1)
              }
              return;
            }

            // rotate the quipu to centre on a target
            if(target !== false){

              if(target === 'centre') target = Math.floor(fullArray.length/2);
              else if(target === 'random') target = Math.floor( Math.random()*(fullArray.length-1) )

              // determine transition speed by how far we’re travelling
              if(currentQuipuIndex == null){
                settings.transitionSpeed = 1200;
              } else if(typeof currentQuipuIndex === 'number'){
                var diff = Math.abs( currentQuipuIndex - target );
                if( diff < 20 )
                  settings.transitionSpeed = 500 + (700 * diff/20)
                else
                  settings.transitionSpeed = 1200;
              }
              currentQuipuIndex = target

              $timeout(resolve, settings.transitionSpeed);

              var nodeCounter = 0;

              var middleSpread = 18 // how many nodes are in the middle section?

              var middle = {
                // node indexes
                startPoint: target - middleSpread/2,
                endPoint:   target + middleSpread/2 + 1,

                // circle angle
                startAngle: -160,
                endAngle:    -20,
              }

              // calculate how far apart the outer nodes should be
              var outerIncrement = (function () {
                var numBefore = middle.startPoint;
                var numAfter = fullArray.length - middle.endPoint;
                var greater = numBefore > numAfter ? numBefore : numAfter;
                return Math.abs(180 + middle.startAngle) / greater;
              })();

              // calculate how far apart the middle nodes should be
              // this is linear for now, in future it should be logarithmic,
              // getting wider towards the middle, to transition between
              // the bunches at the ends and the middle node
              var middleIncrement = (Math.abs(middle.startAngle)-Math.abs(middle.endAngle))/middleSpread

              //// rotate the middle section:
              nodeCounter = 0;
              for( var i = middle.startPoint; i < middle.endPoint; i++ ){
                if(fullArray[i]){
                  d3.select('#node__' + fullArray[i].id)
                    .transition()
                    .duration(settings.transitionSpeed)
                    .attr('transform', function(d) {
                      // return token.ize('rotate({deg})', { deg: middle.startAngle + (middleIncrement * nodeCounter) });
                      return token.ize('rotate({deg})', { deg: middle.startAngle + easeInOutQuad( nodeCounter, outerIncrement, Math.abs(middle.startAngle - middle.endAngle), middleSpread ) });
                    })
                    .style('opacity', threadOpacity)
                }
                nodeCounter += 1;
              }

              //// rotate the selected node to dead centre
              d3.select('#node__' + fullArray[target].id)
                .transition()
                .attr('transform', 'rotate(-90)')
                .duration(settings.transitionSpeed)
                .style('opacity', threadOpacity)


              //// rotate what comes before the middle section
              nodeCounter = 0;
              for( var i = middle.startPoint; i >= 0; i-- ){
                d3.select('#node__' + fullArray[i].id)
                  // .style('display', 'none')
                  .transition()
                  .attr('transform', function(d) {
                    return token.ize('rotate({deg})', { deg: middle.startAngle - nodeCounter * outerIncrement });
                  })
                  .duration(settings.transitionSpeed)
                  .style('opacity', threadOpacity)

                nodeCounter += 1
              }

              //// rotate what comes after the middle section
              nodeCounter = 1;
              for( var i = middle.endPoint; i < fullArray.length; i++ ){
                d3.select('#node__' + fullArray[i].id)
                  .transition()
                  .attr('transform', function(d) {
                    return token.ize('rotate({deg})', { deg: middle.endAngle + nodeCounter * outerIncrement });
                  })
                  .duration(settings.transitionSpeed)
                  .style('opacity', threadOpacity)
                nodeCounter += 1
              }

            } else {

              // no target -> rotate the threads offscreen

              $timeout(resolve, settings.transitionSpeed);
              currentQuipuIndex = target

              elements.threads
                .transition()
                .duration(settings.transitionSpeed)
                .attr('transform', 'rotate(0)')
                .style('opacity', threadOpacity)

            }


          })
        }



        /*

           ####  ##   ##  ######  ##    ##    ###### ##   ## #####  ######  #####  ######   ####
          ##     ##   ## ##    ## ##    ##      ##   ##   ## ##  ## ##     ##   ## ##   ## ##
           ####  ####### ##    ## ## ## ##      ##   ####### #####  #####  ####### ##   ##  ####
              ## ##   ## ##    ## ## ## ##      ##   ##   ## ##  ## ##     ##   ## ##   ##     ##
          #####  ##   ##  ######   ##  ##       ##   ##   ## ##  ## ###### ##   ## ######  #####

        */

        $rootScope.$on('showQuipu', startShowingThreads);

        var frameRunnerParams = { f: showThreads, id: 'showThreads', type: 'everySecond' };

        function startShowingThreads(e, duration){

          showingThreads = true;

          threadsPerSecond = scope.quipuData.length / duration;

          // let’s show threads in a random order so it looks cool
          showArray = [];
          scope.quipuData.map(function(thread){ showArray.push(false) });

          showThreadsListeners.push( $rootScope.$on( 'play', frameRunner.add.bind(null, frameRunnerParams) ) );
          showThreadsListeners.push( $rootScope.$on( 'pause', frameRunner.remove.bind(null, frameRunnerParams) ) );

          showThreadsListeners.push( $rootScope.$on('stopShowingQuipu', stopShowingQuipu ) );

          frameRunner.add(frameRunnerParams);
        }


        function showThreads(){
          var odd = !!( new Date().getSeconds() % 2 )
          var num = odd ? Math.floor(threadsPerSecond) : Math.ceil(threadsPerSecond);
          for (var i = 0; i < num; i++) {
            var index = findHiddenIndex(showArray);
            showArray[index] = true;
            rotateQuipu(null, index);
          }

        }

        // find a hidden thread
        function findHiddenIndex(threadArray){

          var index = Math.floor( Math.random() * scope.quipuData.length );
          var tries = 0;

          while (threadArray[index] === true && tries < scope.quipuData.length) {
            index = Math.floor( Math.random() * scope.quipuData.length );
            tries += 1;
          }

          if(tries >= scope.quipuData.length) stopShowingQuipu()

          return index;
        }

        function stopShowingQuipu(){
          // make sure we’ve shown all the threads
          showArray.map(function(item, index){ if(!item) rotateQuipu(null, index) })
          frameRunner.remove(frameRunnerParams)
        }







        /*

          #####  ######  ####  ####  ##### ######
          ##  ## ##     ##      ##     ##  ##
          #####  #####   ####   ##    ##   #####
          ##  ## ##         ##  ##   ##    ##
          ##  ## ###### #####  #### ###### ######

          (re)draw the quipu, on setup, resize, etc

        */

        $rootScope.$on('resize', resizeQuipu);
        function resizeQuipu(e, dimensions) {

          settings = calculateDimensions(settings, dimensions);

          elements.svg
            .attr("width",  settings.width)
            .attr("height", settings.height)
            .attr('viewBox', '0 0 ' + settings.width + ' ' + settings.height);

          positionQuipu();

          // resize the quipu arc
          var arc = d3.svg.arc()
            .innerRadius(settings.r - 2)
            .outerRadius(settings.r - 3)
            .startAngle(0)
            .endAngle(180 * (Math.PI/180));

          elements.arc
            .attr("d", arc)

          // translate the quipu threads to match the arc’s new radius
          elements.threadsInner
            .attr('transform', token.ize('translate(0,{y})', {y: settings.r}))

          // transform the active thread to match the new window size
          d3.select('.quipu__node--active')
            .select('line')
              .attr("y2", function(d){ return settings.audioPlayerWidth - 50; })

          updatePaths(true);

        }

        function calculateDimensions(settings, dimensions){
          if(!dimensions) dimensions = resize.getDimensions();

          settings.x      = 350
          settings.width  = dimensions.windowWidth;
          settings.height = dimensions.windowHeight;
          settings.dim    = dimensions.windowHeight;
          settings.audioPlayerWidth = dimensions.audioPlayerWidth;

          if(scope.api.viewMode == 'intro' || !scope.api.viewMode)
            settings.r = 240
          else
            settings.r = dimensions.windowHeight / 4;

          return settings;
        }



        /*

          Move the quipu around

          Positions: 'centre', 'side'

        */
        function positionQuipu(duration){

          var transform;

          if(scope.api.viewMode === 'intro' || !scope.api.viewMode){

            transform = token.ize('translate({x},{y}),rotate(90)', {
              x: settings.width/2,
              y: (window.innerHeight > globals.breakpoints.short) ? 450 : 350
            });

          } else if(scope.api.viewMode === 'listen' || scope.api.viewMode === 'record' ){

            transform = token.ize('translate({x},{y}),rotate(0)', {
              x: settings.width-settings.audioPlayerWidth-settings.r,
              y: settings.dim/2
            });

          }

          if(duration){

            elements.quipuRoot
              .transition()
              .duration(duration)
              .attr('transform', transform)

          } else {
            elements.quipuRoot
              .attr('transform', transform);

          }

        }




        /*

           #####  ##   ## ######  ####  ######     ######  ##     ##### ##    ## ###### #####
          ##   ## ##   ## ##   ##  ##  ##    ##    ##   ## ##    ##   ## ##  ##  ##     ##  ##
          ####### ##   ## ##   ##  ##  ##    ##    ######  ##    #######  ####   #####  #####
          ##   ## ##   ## ##   ##  ##  ##    ##    ##      ##    ##   ##   ##    ##     ##  ##
          ##   ##  #####  ######  ####  ######     ##      ##### ##   ##   ##    ###### ##  ##

        */

        // tween the active line out to the width of the audio player
        function openAudioPlayer(threadIndex) {

          // First, let the audio API know that we're transitioning,
          // and thus, not ready.
          scope.audioApi.quipuReady = false;

          // find active line
          var _d = scope.quipuData[threadIndex];
          elements.threads.each(function(d) {
            d3.select(this).classed('quipu__node--active', function() {
              return d === _d ? true : false;
            });
          });

          d3.select('.quipu__node--active')
            .select('line')
              .transition()
              .duration(500)
                .attr("y2", function(d){ return settings.audioPlayerWidth - 50; })
                .each('end', function() {
                  // Transition is complete so, yes, we're ready now.
                  $timeout(function() {
                    scope.audioApi.quipuReady = true;
                  }, settings.transitionSpeed/2);
                })

        };

        // tween all line lengths back to original
        function closeAudioPlayer(){
          elements.threads
            .select('line')
            .transition()
            .duration(500)
            .attr("y2", function(d){ return d.length })
        }




        /*

          ######   ##### ###### ##   ##  ####
          ##   ## ##   ##  ##   ##   ## ##
          ######  #######  ##   #######  ####
          ##      ##   ##  ##   ##   ##     ##
          ##      ##   ##  ##   ##   ## #####

          paths are curves linking knots together

          there are two, for history and theme

          history shows the knots the user has clicker
          theme shows the knots of the currently selected theme, if there is one

        */

        // reset
        function clearPath() {
          paths.theme.data = [];
          elements.svg.selectAll('.tagline').remove();
        };

        // build a theme path
        function buildPath(tag) {

          paths.theme.knots = elements.svg.selectAll('.quipu__knot--' + tag);
          paths.theme.data = [];

          if(paths.theme.knots[0].length){

            // find knot positions
            paths.theme.knots.each(updateKnotPosition);

            // add the menu item circle to the start of the array
            paths.theme.data[0] = { x: 1, y: 1 };

            paths.theme.playheadIndex = scope.audioApi.theme.status() + 1

            paths.theme.path = elements.svg.selectAll('.tagline').data([paths.theme.data])

            paths.theme.path
              .enter()
              .append('svg:path')
                .attr('d', function(d){ return line(d) })
                .style('stroke-width', 1)
                .attr('class', 'tagline quipu__connector--'+scope.audioApi.currentTag)
                .attr('fill', 'none');

            paths.theme.path.exit().remove();
            paths.theme.path = elements.svg.selectAll('.tagline');
          }

        };

        function updateKnotPosition(knot, index){
          var position = this.getBoundingClientRect();

          if(!position) position = {};

          if(!position.left || !position.top){
            position = {
              left: window.innerWidth * 0.3,
              top: window.innerHeight * 0.5,
            }
          }

          paths.theme.data[index+1] = {
            x: position.left + 5,
            y: position.top  + 5,
          }
        }

        function updateHistoryKnotPosition(knot, index){
          var position = knot.getBoundingClientRect();

          if(!position.left && !position.right){

            // if the knot is from a currently active thread,
            // use the play button as the knot location

            paths.history.data[index] = {
              x: window.innerWidth * 0.3,
              y: window.innerHeight * 0.5,
            }
          } else {

            paths.history.data[index] = {
              x: position.left + 5,
              y: position.top  + 5,
            }
          }

        }

        function addHistoryKnot(id, knot){
          paths.history.knots.push(knot);
          paths.history.ids.push(id);
          updateHistoryPath();
        }

        function updateHistoryPath(){
          elements.svg.selectAll('.historyline').remove();

          if (paths.history.knots.length < 2) return;

          paths.history.data = [];

          paths.history.knots.forEach(updateHistoryKnotPosition)

          paths.history.path = elements.svg.selectAll('.historyline')
                                .data([paths.history.data])
                                .enter()
                                .append('svg:path')

          paths.history.path
            .attr('d', function(d){return line(d);})
            .style('stroke-width', 1)
            .style('opacity', 0.3)
            .attr('class', 'historyline')
            .attr("stroke", "white")
            .attr('fill', 'none');

          paths.history.changed = true;
        }


        /*

          update the paths (framerunner)

        */
        function updatePaths(force) {

          // update the history path
          if( paths.history.data.length ){
            if(paths.history.changed || (!scope.audioApi.quipuReady || force) ){
              paths.history.changed = false;
              paths.history.knots.map(updateHistoryKnotPosition);
              paths.history.path.attr('d', function(d){ return line(d) });
            }
          }

          // update the theme path
          if( paths.theme.data.length ){
            var changed = false;

            // is the quipu moving? this is the only time we need to animate all the points
            if(!scope.audioApi.quipuReady || force){
              paths.theme.knots.each(updateKnotPosition);
              paths.theme.playheadIndex = scope.audioApi.theme.status() + 1
              changed = true;
            }

            // are we playing audio? just update the playhead
            if( scope.audioApi.headLocation ){
              paths.theme.data[paths.theme.playheadIndex].x = scope.audioApi.headLocation;
              paths.theme.data[paths.theme.playheadIndex].y = settings.height/2 + 4;
              changed = true;
            } else {
              paths.theme.data[paths.theme.playheadIndex].x = window.innerWidth * 0.3
              paths.theme.data[paths.theme.playheadIndex].y = settings.height/2
            }

            // find the circle location, if we haven’t already
            if(paths.theme.data[0].x === 1){
              var circle = $('.navmenu__circle.mod-'+scope.audioApi.currentTag)
              if(circle.length){
                var offset = circle.offset();
                paths.theme.data[0] = { x: offset.left + 10, y: offset.top + 10 }
              }
              changed = true;
            }

            if(changed) paths.theme.path.attr('d', function(d){ return line(d) });
          }

        };






        /*

          ##    ##  ##### ###### ##### ##   ## ###### #####   ####
          ##    ## ##   ##  ##  ##     ##   ## ##     ##  ## ##
          ## ## ## #######  ##  ##     ####### #####  #####   ####
          ## ## ## ##   ##  ##  ##     ##   ## ##     ##  ##     ##
           ##  ##  ##   ##  ##   ##### ##   ## ###### ##  ## #####

          These are the API watchers.
          Anything that gets called in this directive should go through these.
          They watch the values in quipu.service.js.

        */


        /*

          Transition between themes

        */
        scope.$watch('audioApi.currentTag', changeTheme);
        function changeTheme(newTheme) {
          // console.log('changeTheme', newTheme);
          // restrict to the 4 coloured tags, so we don't see any black lines
          if(['campaign', 'operation', 'lifeafter', 'justice'].indexOf(newTheme) === -1) newTheme = null

          clearPath();
          if (newTheme){
            buildPath(newTheme);
          }

          elements.quipuRoot.attr('class', function(){
            var classsss = 'quipu_root '
            if(newTheme) classsss += 'theme theme-'+newTheme;
            return classsss
          });
        }


        /*

          Transition between intro <-> listen modes
            (todo: this should handle the intro reveal?)

        */
        scope.$watch('api.viewMode', changeMode);

        function changeMode(newMode, oldMode){

          if (newMode === 'intro') {

            elements.svg.classed('mod-intro', true);

            positionQuipu(settings.transitionSpeed)
            rotateQuipu('centre');
            clearPath();

          } else {
            elements.svg.classed('mod-intro', false);
          }

          if (newMode === 'listen'){
            positionQuipu(settings.transitionSpeed)
          } else {
            frameRunner.remove({ id: 'updatePaths'});
          }

          if( newMode === 'record' ){

            elements.svg.classed('mod-record', true);
            positionQuipu(settings.transitionSpeed)
            rotateQuipu();
            clearPath();

          } else {
            elements.svg.classed('mod-record', false);

          }

        }




        /*

          Change quipu threads
            triggers animated transition

        */
        scope.$watch('api.thread', changeThread);

        function changeThread(threadId, oldThreadId) {
          // console.log('changing quipu thread:', oldThreadId, '->', threadId);

          closeAudioPlayer();

          // rotate the quipu to the selected thread
          var indexes = scope.quipuData.map(function(obj, index) {
            if(obj.id === threadId) return index;
          }).filter(isFinite);

          // hide threads if we’re playing the intro
          hideThreads = !!((threadId === globals.introUID));

          updateListenedStatus();

          rotateQuipu(indexes[0])
            .then(openAudioPlayer.bind(null, indexes[0]));

        }

        $rootScope.$on('showAllThreads', function(){

          var indexes = scope.quipuData.map(function(obj, index) {
            if(obj.id === globals.introUID) return index;
          }).filter(isFinite);

          hideThreads = false;

          rotateQuipu(indexes[0]);

        })







        /*

          Clean up after yourself

        */
        scope.$on('$destroy', function(){

          frameRunner.remove({ id: 'updatePaths'});

          stopShowingQuipu()
          showThreadsListeners.map(function(listener){ listener(); });

        })


      }
    }
  }
})();