/*

  Quipu View Controller

    second-level controller:
      appCtrl
        quipuCtrl
          ...

    children:
    - background
    - quipu-svg
    - quipu-interface
    - etc

*/
;(function () {
  'use strict';

  angular
    .module('angularApp')
    .controller('quipuCtrl', quipuCtrl);

  function quipuCtrl($scope, $state, globals, QuipuAPI, AudioAPI, BackgroundAPI, loadData, ambientAudio, frameRunner, ga) {

    var firstLoad = true;

    $scope.appData.loaded = true; // dismiss the loading spinner

    // Ambient audio
    AudioAPI.ambient = $scope.ambient = []
    $scope.ambient = ['landing_v3'];

    $scope.$watchCollection('ambient', ambientAudio.update);

    frameRunner.add({ id: 'tweenUpdate', f: function(){ TWEEN.update()} });

    $scope.viewData = {};

    $scope.$on('$stateChangeSuccess', stateChange);

    stateChange();

    // ********************************************************

    function stateChange(event, toState, toParams, fromState, fromParams){
      if(!event) return;

      ga.pageview();

      globals.lang = $state.params.lang;

      if($state.current.name === 'quipu.intro'){

        $scope.ambient = ['landing_v3'];
        AudioAPI.currentTag = undefined

        BackgroundAPI.updateBackground('splash', null);
        QuipuAPI.viewMode = 'intro';
        QuipuAPI.thread = null

      } else if($state.current.name === 'quipu.listen') {

        $scope.ambient = ['Ambiente_v3'];

        if( ! $state.params.id )
          $state.go('quipu.listen', { id: AudioAPI.eventData[10].UID })

        QuipuAPI.thread = $state.params.id || null
        QuipuAPI.viewMode = 'listen';

        if(toParams.id !== fromParams.id){

          // hide entire interface for the intro thread
          $scope.appData.hideInterface = $scope.appData.hideInterfaceNonCore = (QuipuAPI.thread === 'intronode') ? true : false;

          if(QuipuAPI.thread === 'intronode' || QuipuAPI.thread === 'what_is_quipu'){
            BackgroundAPI.updateBackground('none');
          } else {
            if( $state.params.view !== 'knot' )
              BackgroundAPI.updateBackground('library', 'GENERIC'); // generic

            BackgroundAPI.disableSyncedVid();
          }
        }


        // determine current tag
        if($state.params.view === 'knot' && $state.params.tag){
          AudioAPI.currentTag = $state.params.tag
        } else if($state.params.view !== 'knot'){
          AudioAPI.currentTag = undefined
        }

      } else if( $state.current.name === 'quipu.takeaction' ){

        $scope.ambient = [];

        AudioAPI.currentTag = undefined;
        QuipuAPI.viewMode = 'record';
        QuipuAPI.thread = null;
        $scope.appData.hideInterface = false;
      }

      firstLoad = false;

    }

  }
})();