;(function () {
  'use strict';

  angular
    .module('angularApp', [
      'ui.router',
      'ngAnimate',
      // 'ngTouch',

      'angularApp.services',
      'angularApp.directives',
      'angularApp.controllers',
      'heliosFrameRunner',
      'localStorage'
    ])

    .config(function($sceDelegateProvider){
      $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        // 'https://phoneline.quipu-project.com/*'
      ]);
    })

    // .run(function($rootScope, $state, globals){

    //   $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
    //     event.preventDefault();
    //     console.log('state change error:', error);
    //     // if(error === 'nothread'){
    //     //   return $state.go('quipu.listen', { id: globals.introUID })
    //     // } else {
    //     return $state.go('unsupported');
    //       // }
    //   });

    // })

})();
