;(function () {
  'use strict';

  angular
    .module('angularApp.services')
    .factory('QuipuAPI', QuipuAPI)

    function QuipuAPI() {

      var exports = {
        thread: null,
        eventData: null,
        viewMode: null
      }


      return exports;
    }

})();