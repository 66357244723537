/*

  App Controller

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.controllers', [])
    .controller('appCtrl', appCtrl)

  function appCtrl($scope, $state, $location, $rootScope, QuipuAPI, storage, globals) {

    $scope.appData = {
      loaded: false,
      debug:  false,
      bodyClass: ['no-touch'],

      // intro sequential reveal
      hideInterface: false,
      hideInterfaceNonCore: false, // menus etc
    };

    if ( storage.get('quipuDebug') ){
      console.log('/* DEBUG */');
      $scope.appData.debug = true;
    }

  }


})();