'use strict';

angular
  .module('angularApp.controllers')
  .controller('audioCtrl', function($scope, $location, $stateParams, $timeout, $q, AudioAPI) {

    AudioAPI.quipuReady = false;
    AudioAPI.currentTime = $stateParams.currentTime || 0;

    /**
     * Update the SRT location.
     */
    $scope.$on('$locationChangeSuccess', function(n) {
      AudioAPI.currentTime = $location.search().currentTime;
    });

  });