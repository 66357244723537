/*

  Audio Timelines: Subtitles, Popcorn, etc

    child of audio-player directive

    Popcorn onStart and onEnd events handle the knot functionality:
      backgrounds, captions, skips, etc etc

*/

(function () {
  'use strict';

  angular
    .module('angularApp.directives')
    .directive('audioTimelines', audioTimelines);

  function audioTimelines($rootScope, $timeout, $location, $state, $stateParams, $q, $filter, dataStore, globals, frameRunner, timeFormat, CDN, resize, storage, Utilities, BackgroundAPI, AudioAPI, QuipuAPI) {
    return {
      restrict: 'E',
      replace: true,
      require: '^audioPlayer',
      templateUrl: 'js/modules/audio-player/audio-timelines.template.html',

      // inherit the controller from audio-player.directive.js
      link: function(scope, elem, attr, audioCtrl) {

        // var playhead = elem.find('.audio__playhead');
        var playhead = elem.find('#playhead')[0];
        var timeCode = document.querySelector('.audio__time');

        var tags = [ 'campaign', 'operation', 'lifeafter', 'justice' ]

        scope.currentRegion = null;
        scope.subtitleOffset = 0;

        var subtitleTimeout;

        var timelineWidth = false;
        var offsetLeft = false;

        var beepAudio = document.createElement('audio');
        beepAudio.src = 'assets/beep.mp3';
        beepAudio.volume = 0.7;

        // Functions
        scope.jumpTo = jumpTo;
        scope.skipRegion = skipRegion;
        scope.expandContract = expandContract;

        scope.togglePlay = function() {
          scope.status.isPlaying ? audioCtrl.pause() : audioCtrl.play();
        };

        scope.clearTag = function() {
          $state.go('quipu.listen', { tag: undefined, view: 'thread' })
        };

        scope.$on('initTimelines', setup);

        // ********************************************************

        function setup() {

          /**
           * Update the playhead location when the currentTime changes.
           */
          scope.$watch('api.currentTime', function(newVal, oldVal) {

            if (!isNaN(parseFloat(newVal)) && isFinite(newVal) && newVal !== oldVal) {
              scope.jumpInProgress = true;

              // If it's not loaded the load callback will take care of the skip.
              audioCtrl.skip(+newVal);
            }
          });

          scope.$on('$destroy', function(){
            frameRunner.remove({ id: 'updatePlayhead' });
          })

          $rootScope.$on('resize', onResize);

          buildSubtitles()
            .then(function() { return buildRegions(); })
            .then(function() { return optionallyShowTitleCard(); })
            .then(function() {
              timelinesReady();
            });
        }



        /*

          skip to the end of the current region
          (continue button)

        */

        function skipRegion(){

          // skip intro
          if($state.params.id === globals.introUID){

            console.log('SKIP INTRO');

            audioCtrl.pause();
            scope.appData.hideInterface = false;
            scope.appData.hideInterfaceNonCore = false;
            BackgroundAPI.disableSyncedVid();
            BackgroundAPI.updateBackground('library', 'GENERIC');

            $rootScope.$emit('showAllThreads')

            // SHOW THE THREADS

            var go = function(){
              $state.go('quipu.listen', { id: AudioAPI.eventData[0].UID, currentTime: undefined })
            }

            $q(function(resolve, reject){
              globals.helpShown ? reject() : resolve()
            })
              .then(
                function(){ return scope.showHelp() },
                go
              )
              .then(go)

          } else if($state.params.view === 'knot'){

            var next = AudioAPI.theme.next( $state.params.tag )
            if(next){
              $state.go('quipu.listen', {
                id: next.thread.UID,
                currentTime: next.region.timeIn || 0,
              });
            }


          } else if($state.params.view === 'thread'){
            var nextThread = AudioAPI.find.nextThread($state.params.id)
            AudioAPI.quipuReady = false;
            $state.go('quipu.listen', { id: nextThread.UID })
          }

        }




        /*

          updatePlayhead
            and the active region, and the timecode

            (there is a separate playhead element for each region)

        */
        // scope.mediaEl[0].addEventListener('timeupdate', updatePlayhead);

        function updatePlayhead(){
          if (!scope.status.isReady) { return; }

          var currentTime = scope.mediaEl[0].currentTime;
          var duration    = scope.mediaEl[0].duration;
          var px = 0;

          // update timecode
          timeCode.innerHTML = timeFormat(duration - currentTime) // + '/' + timeFormat(duration)

          // update playhead
          if( !timelineWidth || !offsetLeft ) onResize();

          if(scope.status.isExpanded){

            px = timelineWidth * (currentTime/duration);

          } else {

            var region = getCurrentRegion(currentTime);
            if(region){
              if (scope.currentRegion !== region.id)
                $timeout(function() { scope.currentRegion = region.id; });

              var percent = (currentTime - region.start) / (region.duration);
              px = (timelineWidth * region.left/100) + (timelineWidth * region.width/100 * percent);
            }

          }

          playhead.style.transform = 'translateX(' + px + 'px)';

          // update playhead location in absolute pixels, for the history thread
          if($stateParams.view === 'knot'){
            AudioAPI.headLocation = offsetLeft + px;
          }

        }




        /*

          A helper to get the region that the playhead is in currently.

        */
        function getCurrentRegion(timekey) {
          if(!scope.regions) return;
          if(!scope.regions[0]) return;

          if (timekey <= scope.regions[0].end) {
            return scope.regions[0];
          }

          for (var i=0; i<scope.regions.length; i++) {
            if (timekey >= scope.regions[i].start && timekey <= scope.regions[i].end) {
              return scope.regions[i];
            }
          }
        };



        /*

          Use this function to skip around in the audio track. It will
          update the URL and everything else accordingly.

          @param time [int]. The time you want to skip to.
          @param pause [bool]. Use true if you want the track to pause after skip.

        */
        function jumpTo(time, pause) {
          if (!time) { return; }
          $timeout(function() {
            $location.search('currentTime', time);
            if (!pause) {
              audioCtrl.play();
            } else {
              audioCtrl.pause();
            }
          });
        }



        /*

          This function is called directly by the ng-click event on the audio
          regions.

        */
        scope.seekTo = seekTo
        function seekTo($event, region){
          if(!timelineWidth) return

          var widthInPx = timelineWidth * region.width/100
          var progressInRegion = $event.offsetX / widthInPx;

          var timeInRegion = progressInRegion * (region.end - region.start)
          var overallTime = region.start + timeInRegion;

          jumpTo(overallTime);
        }

        // seek using the audio region container object
        // (so we can seek to areas where there are no regions, in expanded mode)
        scope.seekToFull = seekToFull;
        function seekToFull($event){
          if(!timelineWidth || !scope.status.isExpanded) return
          var progress = $event.offsetX / timelineWidth;
          var time = progress * scope.viewData.duration;
          jumpTo(time);
        }







        /*

           ####  ##   ## ###### ###### #### ###### ##    ######  ####
          ##     ##   ## ##   ##  ##    ##    ##   ##    ##     ##
           ####  ##   ## ######   ##    ##    ##   ##    #####   ####
              ## ##   ## ##   ##  ##    ##    ##   ##    ##         ##
          #####   #####  ######   ##   ####   ##   ##### ###### #####

          Build the top timeline track with subtitles.

        */
        function buildSubtitles() {
          return $q(function(resolve, reject) {

            // destroy and reattach popcorn
            if (scope.popcorn) Popcorn.destroy( scope.popcorn );
            scope.popcorn = Popcorn(scope.mediaEl[0], {
              defaults: {
                subtitle: {
                  target: 'subtitle-dummy'
                }
              }
            });

            var chooseSRTCDN = CDN.srts()

            var srt = scope.popcorn.parseSRTQuipu(chooseSRTCDN + scope.testimonyData.SRT, function() {

              var lastTimeIn = 0;
              var subtitleData = [{
                text: '',
                subtitleClasses: 'audio__subtitle audio__subtitle--current audio__subtitle--starter'
              }];
              var subTexts = srt.getTrackEvents();

              _.each(subTexts, function(o) {

                if (!o.target) { return; }
                var data = scope.testimonyData;

                o.subtitleClasses = ['audio__subtitle'];
                o.regionClasses = ['audio__region'];

                var hasEvent = false;
                for (var i=0; i<scope.testimonyData.events.length; i++) {
                  var event = scope.testimonyData.events[i];
                  var time_in = Utilities.timeFormat(event.time_in);
                  var time_out = Utilities.timeFormat(event.time_out);
                  var tag = Utilities.safeString(event.Tag);

                  if (o.end >= time_in && o.start <= time_out) {
                    o.tag = tag;

                    o.subtitleClasses.push('audio__subtitle--' + tag);
                    hasEvent = true;
                    if (lastTimeIn !== time_in && event.isKnot) {
                      o.showknot = true;
                      o.subtitleClasses.push('audio__subtitle--has-knot');
                      o.knotIn = time_in;
                    }
                    lastTimeIn = time_in;
                  }
                }

                // hide subtitles that aren’t from regions - don’t do this
                // if (!hasEvent) {
                //   o.subtitleClasses.push('audio__subtitle--no-event');
                // }

                subtitleData.push(o);

              });

              /**
               * All the event data has been added to the srt data.
               * Now bind it to the front end.
               */
              $timeout(function() {
                scope.subtitles = subtitleData;
              });

              $timeout(function() {
                _.each(subTexts, function(o) {
                  scope.popcorn.code({
                    start: o.start + 0.1,
                    end: o.end,
                    onStart: function( options ) {

                      var el = elem.find('#audio__subtitle--' + o.id);
                      if (!el.length) { return; }

                      if(subtitleTimeout) $timeout.cancel(subtitleTimeout)

                      elem.find('.audio__subtitle--current').removeClass('audio__subtitle--current');

                      el.addClass('audio__subtitle--current');
                      el.prevAll().addClass('audio__subtitle--past');
                      el.nextAll().removeClass('audio__subtitle--past');

                      var position = el.position();

                      if (position && !isNaN(position.left)) {
                        $timeout(function() {
                          scope.subtitleOffset = -(position.left);
                        });
                      }

                    },
                    onEnd: function( options ){
                      subtitleTimeout = $timeout(function(){
                        elem.find('.audio__subtitle--current').removeClass('audio__subtitle--current');
                      }, 1000);
                    }
                  });
                });
              });

            });

            resolve('SRTs built.');
          });
        };





        /*

          #####  ######  #####  ####  ######  ###  ##  ####
          ##  ## ##     ##       ##  ##    ## #### ## ##
          #####  #####  ##  ###  ##  ##    ## ## ####  ####
          ##  ## ##     ##   ##  ##  ##    ## ##  ###     ##
          ##  ## ######  #####  ####  ######  ##   ## #####

          Build the bottom (region) timeline track, AND all region events

        */
        function buildRegions(audioId) {
          return $q(function(resolve, reject) {

            ///// convert testimony data into regions
            var regions = [];
            var duration = scope.viewData.duration;

            var leftRunningTotal = 0;

            scope.testimonyData.events.map(function(region, i){

              var regionID = 'region-' + i;
              var time_in  = Utilities.timeFormat(region.time_in);
              var time_out = Utilities.timeFormat(region.time_out);
              var tag      = Utilities.safeString(region.Tag);

              // calculate css percentages
              var left  = ((time_in / duration) * 100);
              var width = ((time_out - time_in) / duration)*100;

              var obj = {
                id:    regionID,
                tag:   tag,

                start: time_in,
                end:   time_out,
                duration: time_out - time_in,

                left:  leftRunningTotal,
                width: width,

                // style when regions are collapsed, showing only the 'radio edit'
                style: {
                  left: leftRunningTotal + '%',
                  width: width + '%'
                },

                // style when regions are expanded to play the entire testimony
                expandedStyle: {
                  left:  left + '%',
                  width: width + '%'
                }
              };

              leftRunningTotal += width;

              /*

                ###### ##  ## ###### ###  ## ###### ####
                ##     ##  ## ##     #### ##   ##  ##
                #####  ##  ## #####  ## ####   ##   ####
                ##      ####  ##     ##  ###   ##      ##
                ######   ##   ###### ##   ##   ##  #####

              */
              scope.popcorn.code({
                start: time_in + 0.1,
                end:   time_out,

                /*

                  Region Start

                */
                onStart: function( options ) {
                  $timeout(function(){
                    scope.jumpInProgress = false;

                    if(region.Action === 'caption'){
                      var captionId = region.File;
                      var caption = AudioAPI.captionData[captionId][0]

                      if(AudioAPI.captionData[captionId]){
                        scope.viewData.caption = globals.lang === 'es' ? caption['Text-es'] : caption.Text
                      }

                    } else if(region.Action === 'background'){

                      BackgroundAPI.updateBackground(region.MediaType, region.File);

                    } else if(region.Action === 'intro'){

                      // special actions for the intro only (MediaType is the actual action)
                      if(region.MediaType === 'introtitle'){
                        // var title = dataStore.translations[][globals.lang];
                        scope.viewData.introtitle = region.File;
                      } else if(region.MediaType === 'show_core_interface'){
                        scope.appData.hideInterface = false;
                      } else if(region.MediaType === 'show_all_interface'){
                        scope.appData.hideInterfaceNonCore = false;
                      } else if(region.MediaType === 'show_quipu'){

                        // var amount = parseInt(region.File.replace('%', '')) / 100;
                        // $rootScope.$emit('addThreads', amount);

                        // tell the quipu svg directive to start revealing the threads
                        var duration = Utilities.timeFormat(region.time_out) - Utilities.timeFormat(region.time_in);
                        $rootScope.$emit('showQuipu', duration)
                      }

                    }
                  })
                },

                /*

                  Region End

                */
                onEnd: function( options ) {
                  if (scope.jumpInProgress) return;
                  $timeout(function(){
                    var nextEvent = scope.testimonyData.events[i+1];

                    if(region.Action === 'intro'){

                      if(region.MediaType === 'introtitle'){
                        scope.viewData.introtitle = undefined;
                        console.log('scope.viewData.introtitle', scope.viewData.introtitle);
                      } else if(region.MediaType === 'show_quipu'){

                        $rootScope.$emit('stopShowingQuipu');

                      } else if(region.MediaType === 'show_help'){
                        scope.showHelp()
                         .then(function(){
                            // go to first thread
                            $state.go('quipu.listen', {
                              id: scope.api.eventData[0].UID,
                              currentTime: undefined,
                              view: 'thread'
                            })
                         })
                      }
                      return;
                    } else if(region.Action === 'caption'){
                      // clear the caption
                      scope.viewData.caption = '';
                      return
                    }


                    if (AudioAPI.currentTag && tag === 'presentation') {

                      // There is a theme selected but we're just doing the intro.
                      // So now go to the current theme region.
                      var branch = _.findWhere(scope.testimonyData.events, { Tag: AudioAPI.currentTag });
                      scope.jumpTo(Utilities.timeFormat(branch.time_in));

                    } else if (AudioAPI.currentTag && (!nextEvent || !nextEvent.Tag || nextEvent.Tag !== tag)) {


                      // There is a theme selected and we’ve reached the end of this
                      // testimony. Go to the next testimony in this theme.
                      var next = AudioAPI.theme.next(tag);
                      if(!next) return;

                      $state.go('quipu.listen', {
                        id: next.thread.UID,
                        currentTime: next.region.timeIn,
                        view: 'knot',
                        tag: tag
                      })

                    } else if (!nextEvent) {

                      // There is nothing after this in the current testimony
                      // and there is no theme to automatically jump to, so pause
                      audioCtrl.pause();

                    } else if(!scope.status.isExpanded) {

                      // Default behaviour is to just continue to the next region in the testimony
                      // unless we’re in expanded mode, when we don’t skip, we play right through

                      // if the next event is a caption, it shouldn't trigger a skip
                      if(nextEvent.Action !== 'caption' && nextEvent.Action !== 'quipu_move'){
                        var pauseOnJump = region.Action === 'pause' ? true : false;
                        scope.jumpTo(Utilities.timeFormat(nextEvent.time_in), pauseOnJump);
                      }

                    }
                  })
                }

              });

              regions.push(obj);
            });

            scope.regions = regions;
            resolve('Regions Built.');
          });
        };


        /**
         * A helper function to make sure the regions array is populated
         * before initiating playback. It only getts called at the end
         * of scope.buildRegions();
         */
        function timelinesReady() {
          $timeout(function() {
            scope.jumpInProgress = true;
            audioCtrl.skip(+AudioAPI.currentTime);
            audioCtrl.play();
            beep();
            frameRunner.add({ id: 'updatePlayhead', f: updatePlayhead })
          });
        };


        function beep(){

          if($state.params.currentTime)
            if($state.params.currentTime != 0)
              return;

          if(scope.testimonyData.Role !== 'testimony')
            return;

          if(AudioAPI.muted || storage.get('quipuMute')) return

          beepAudio.currentTime = 0;
          beepAudio.play();

        }








        /*

          Title Card

        */

        function optionallyShowTitleCard(){
          if($state.params.view === 'knot'){
            return showTitleCard()
          } else {
            return $q(function(resolve, reject){
              resolve()
            })
          }

        }

        function showTitleCard(){

          return scope.titleCard($state.params.tag)
            .then(function(whereTo){
              if(whereTo === 'continueTheme'){

                AudioAPI.theme.increment($state.params.tag);
                return;

              } else if(whereTo === 'nextTitle'){

                AudioAPI.theme.increment($state.params.tag);
                return showTitleCard($state.params.tag);

              } else if(whereTo === 'nextTheme'){

                var currentTagIndex = tags.indexOf($state.params.tag);
                if(currentTagIndex >= tags.length) currentTagIndex = 0;

                var newTag = tags[currentTagIndex+1];

                var next = AudioAPI.theme.next(newTag);

                $state.go('quipu.listen', {
                  id: next.thread.UID,
                  currentTime: next.region.timeIn,
                  view: 'knot',
                  tag: newTag
                })

              } else if(whereTo === 'backToQuipu'){

                $state.go('quipu.listen', {
                  id: $state.params.id,
                  currentTime: scope.mediaEl[0].currentTime,
                  view: 'thread'
                })
              }

            })
        }




        /*

          Expand/Contract Timeline

        */

        function expandContract(){

          scope.status.isExpanded = !scope.status.isExpanded
          $state.go($state.current.name, { expanded: scope.status.isExpanded ? 1 : 0 });

        }




        /*

          Resize

        */

        function onResize(){
          timelineWidth = $('.audio__regions').width();
          offsetLeft = Math.round( window.innerWidth * 0.3 + 20 );
        }


      }
    }
  }

})();
