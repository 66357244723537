/*

  Background

    BackgroundAPI.updateBackground(type, file)
      change background file

    BackgroundAPI.nextInLibrary()
      go to the next background file in the current library

    BackgroundAPI.updateCaption(text)
      show a caption

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.services')
    .factory('BackgroundAPI', BackgroundAPI);

  function BackgroundAPI($q, CDN, globals) {

    var syncedvid;

    var exports = {

      mediaItem: null,

      isPlaying: false,
      libraries: null,
      showCaption: null,

      updateBackground: updateBackground,
      updateCaption: updateCaption,
      nextInLibrary: nextInLibrary,

      syncedVideo: syncedVideo,
      disableSyncedVid: disableSyncedVid

    }

    /*

      Change background

    */
    function updateBackground(type, file) {
      // console.log('updateBackground:', type, file);

      if(type === 'none'){

        // hide background
        exports.mediaItem = { 'MEDIA_TYPE': 'none' }

      } else if (type === 'library') {

        // media is from a media library
        var library = exports.libraries[file];

        if (!library) {
          exports.mediaItem = null;
          return;
        }

        var index = library.current;
        var item = library.items[index];
        item.location = 'libraries/'

        // advance our current index
        library.current += 1;
        if(library.current >= library.items.length)
          library.current = 0;

        exports.mediaItem = item;

      } else if(type === 'splash'){

        exports.mediaItem = {
          'MEDIA_TYPE': 'video',
          'FILE': 'LANDING_PAGE.webm',
          'loop': true
        }

      } else if(type === 'video'){

        exports.mediaItem = {
          'MEDIA_TYPE': 'video',
          'FILE': file
        }
      }

    }


    /*

      syncedVideo (for intro and why quipu)

    */
    function syncedVideo(file){
      return $q(function(resolve, reject){
        var split = file.split('.');

        if(!syncedvid) syncedvid = document.getElementById('syncedvid');

        var filePath = CDN.audio + file;
        if( globals.videoType === 'mp4' ){
          filePath = CDN.audio + split[0] + '.mp4'
        }
        syncedvid.src = filePath;

        var canplaythrough = function(){
          syncedvid.removeEventListener('canplaythrough', canplaythrough);
          $(syncedvid).fadeIn(1000);
          resolve();
        }

        syncedvid.addEventListener('canplaythrough', canplaythrough, false);

        syncedvid.play();

      })
    }

    /*

      Kill the synced vid, kill it dead

    */
    function disableSyncedVid(){
      if(!syncedvid) syncedvid = document.getElementById('syncedvid');
      if(!syncedvid) return;
      syncedvid.pause();
      syncedvid.src = ''
      $(syncedvid).fadeOut(1000);
    }

    /*

      Advance to the next video in a given library
        (because the existing one is done but the audio is still playing)

    */
    function nextInLibrary(){
      if(!exports.mediaItem) return;
      if(!exports.mediaItem.LIBRARY_NAME) return;

      var currentLibrary = exports.mediaItem.LIBRARY_NAME;

      var library = exports.libraries[currentLibrary]

      library.current += 1;
      if(library.current >= library.items.length)
        library.current = 0;

      var index = exports.libraries[currentLibrary].current;

      exports.mediaItem = library.items[index];

      // console.log('nextInLibrary', index, exports.mediaItem);
    }

    /*

      Update the background caption

    */
    function updateCaption(_text)  {
      // console.log('[background] updateCaption', _text);
      exports.showCaption = true
      exports.captionText = _text
    }

    return exports;
  }
})();