/*

  Title Card

    used in audio-timelines.directive.js

    scope.titleCard(theme)
     .then(function(){ continue })

*/
;(function () {

  'use strict';

  angular
    .module('angularApp.directives')
    .directive('titleCard', titleCard);

  function titleCard($q, $timeout, resize, AudioAPI, globals) {
    return {

      restrict: 'E',
      replace: true,
      require: '^audioPlayer',
      templateUrl: 'js/modules/audio-player/audio-titlecard.template.html',

      link: function(scope, $element, attrs, audioCtrl) {

        var timeout, deferred

        scope.titleCard = function(theme){
          deferred = $q.defer();
          audioCtrl.pause()

          var dimensions = resize.getDimensions();
          var caption = AudioAPI.theme.nextTitle(theme);

          if(!caption){
            deferred.resolve('continueTheme');
          } else if(!caption.data){
            deferred.resolve('continueTheme');
          } else {
            scope.card = {
              text: globals.lang === 'es' ? caption.data['Text-es'] : caption.data.Text,
              style: { left: '30%'},
              theme: theme,
              last: caption.last
            }

            scope.status.titleCard = true;

            // 2s + 20 characters per second reading speed
            var duration = 2000 + scope.card.text.length * (1000/15)
            timeout = $timeout(scope.go.bind(null,  'continueTheme'), duration);
          }

          return deferred.promise;
        }

        function closeCard(){
          scope.status.titleCard = false;
          if(timeout) $timeout.cancel(timeout);
        }

        scope.go = function(where){
          closeCard();
          deferred.resolve(where);
        }

      }

    }
  }

})();