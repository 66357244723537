/*

  Resize

    usage:

    $rootScope.$on('resize', function(e, dimensions){
      // resize your component, using the dimensions object
    })

*/
;(function () {
  'use strict';

  angular
    .module('angularApp.services')
    .factory('resize', resize);

  function resize($window, $rootScope) {

    var dimensions = {
      windowHeight: 0,
      windowWidth:  0,
      r: 0,
      audioPlayerWidth: 0,
    }

    getDimensions(true);

    var debouncedResize = _.debounce(function(){
      getDimensions(true);
    }, 100, false);
    angular.element($window).bind('resize', debouncedResize);

    /*

      Resize event

    */
    function getDimensions(shouldEmit){

      // dimensions
      dimensions.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      dimensions.windowWidth  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      dimensions.r = dimensions.windowHeight/4;
      dimensions.audioPlayerWidth = dimensions.windowWidth * 0.7

      if(shouldEmit)
        $rootScope.$emit('resize', dimensions);

      return dimensions;
    }

    return {
      // todo: remove these two
      windowHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      windowWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,

      getDimensions: getDimensions,
    }
  }

})();
